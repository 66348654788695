import React, { useEffect, useState } from "react";
import "./TablePage.css";
import Logo from "../../../assets/img/bs.png";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { InputBase, TableHead, TableSortLabel } from "@material-ui/core";
import { useForm } from "react-hook-form";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import { Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import userIcon from "../../../assets/img/user_icon.png";
import PropTypes from "prop-types";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { Search, Add, CloudUpload } from "@material-ui/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addProperty,
  listProperty,
  deleteProperty,
  updateProperty,
  statusProperty,
} from "../../../actions/property";
import { setInitalState } from "../../../actions/index";
import { bulkUpload } from "../../../actions/upload";
import {
  statusPropertyPending,
  statusPropertyResponse,
  statusPropertyError,
  addPropertyPending,
  addPropertyResponse,
  addPropertyError,
  updatePropertyPending,
  updatePropertyResponse,
  updatePropertyError,
  deletePropertyPending,
  deletePropertyResponse,
  deletePropertyError,
  listPropertyPending,
  listPropertyResponse,
  listPropertyError,
  bulkUploadPending,
  bulkUploadResponse,
  bulkUploadError,
} from "../../../reducers/index";
import moment from "moment";
import Images from "./../../../assets/images.zip";
import SampleCdr from "./../../../assets/sample-cdr.xlsx";
import SampleCDRFile from "./../../../assets/cdr.zip";
import { Link } from "react-router-dom";

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  const useStyles2 = makeStyles((theme) => ({
    table: {
      minWidth: 500,
    },
    grow: {
      flexGrow: 1,
    },
    material_ui_button:{
      padding: '0px 20px 0px 20px',
    },
    navbarbrand:{
     display: "flex",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  }));
  
  // Sorting function for data table
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  
  // customer header for table
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: 16,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  // set table column
  const columns = [
    {
      id: 1,
      field: "serial",
      label: "#",
    },
    {
      id: 2,
      field: "name",
      label: "Name",
    },
  
    {
      id: 12,
      field: "email",
      label: "Email",
    },
  //   {
  //     id: 13,
  //     field: "developer",
  //     label: "Developer",
  //   },
    {
      id: 13,
      field: "p_status",
      label: "Status",
    },
  
    {
      id: 20,
      field: "action",
      label: "Action",
    },
  ];
  
  // table header with sorting
  const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"default"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
              >
                {headCell.label}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  // table pagination setup
  const TablePaginationActions = (props) => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  };
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  // modal dialogue title
  const DialogTitle = withStyles((theme) => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
      postion: "absolute",
      top: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
  }))((props) => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  // modal dialogue content
  const DialogContent = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      maxHeight: "500px",
    },
  }))(MuiDialogContent);
  

 
const User = (props) => {
    const classes = useStyles2();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("address");
  const { register, handleSubmit, errors, reset } = useForm();
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFilePreview, setSelectedFilePreview] = useState(null);
  const [selectedFile1Preview, setSelectedFile1Preview] = useState(null);
  const [cdrFilePreview, setCdrFilePreview] = useState(null);
  const [bulkUploadFile, setBulkUploadFile] = useState(null);
  const [bulkUploadImageFile, setBulkUploadImageFile] = useState(null);
  const [bulkUploadCDRFile, setBulkUploadCDRFile] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [deleteDataId, setDeleteDataId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [viewData,] = useState(null);
  const [propertyShow, setPropertyShow] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [order_by_field, setOrderByField] = useState("address");
  const [order_by_value, setOrderByValue] = useState("asc");
  const [totalData, settotalData] = useState(0);
  const [userData, setUserData] = useState(null);

  // sorting function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByField(property);
    setOrderByValue(isAsc ? "dsc" : "asc");
    setOrderBy(property);
    props.listProperty({
      page_num: currentPage + 1,
      per_page: perPage,
      search: search,
      order_by_field: property,
      order_by_value: isAsc ? "dsc" : "asc",
    });
  };
  // set function for if data is empty
  const emptyRows =
    perPage - Math.min(perPage, totalData - currentPage * perPage);

  //use effect
  useEffect(() => {
    if (!localStorage.getItem("userData")) {
      props.history.push("/");
    }

    let userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
    setUserData(userData);

    if (!propertyShow) {
      setPropertyShow(true);
      setTimeout(() => {
        props.listProperty({
          page_num: currentPage + 1,
          per_page: perPage,
          search: search,
          order_by_field: order_by_field,
          order_by_value: order_by_value,
        });
      }, 1000);
    }
    if (props.deletePropertyResponse) {
      snackClick("Deleted Successfull");
      setOpenDeleteModal(false);
      props.setInitialState();
      props.listProperty({
        page_num: currentPage + 1,
        per_page: perPage,
        search: search,
        order_by_field: order_by_field,
        order_by_value: order_by_value,
      });
    } else if (props.deletePropertyError) {
      snackClick("Something went wrong. !lease try again.");
    }

    if (props.listPropertyResponse) {
      if (props.listPropertyResponse.length > 0) {
        settotalData(
          props.listPropertyResponse[0].length > 9
            ? props.listPropertyResponse[1].Total
            : props.listPropertyResponse[0].length
        );
        setPropertyData(props.listPropertyResponse[0]);
      }
    } else if (props.listError) {
      if (props.listError.status === 403) {
        snackClick(props.listError.error);
        props.setInitialState();
        localStorage.removeItem("userData");
        props.history.push("/");
      }
    }
    if (props.addPropertyResponse) {
      snackClick(props.addPropertyResponse.result);
      setOpenAddModal(false);
      reset();
      props.setInitialState();
      props.listProperty({
        page_num: currentPage + 1,
        per_page: perPage,
        search: search,
        order_by_field: order_by_field,
        order_by_value: order_by_value,
      });
    } else if (props.error) {
      snackClick("something went wrong");
    }

    if (props.statusPropertyResponse) {
      snackClick(props.statusPropertyResponse.result);
      props.setInitialState();
      props.listProperty({
        page_num: currentPage + 1,
        per_page: perPage,
        search: search,
        order_by_field: order_by_field,
        order_by_value: order_by_value,
      });
    } else if (props.statusPropertyError) {
      snackClick("something went wrong");
    }

    if (props.updatePropertyResponse) {
      snackClick(props.updatePropertyResponse.result);
      setOpenEditModal(false);
      reset();
      props.setInitialState();
      props.listProperty({
        page_num: currentPage + 1,
        per_page: perPage,
        search: search,
        order_by_field: order_by_field,
        order_by_value: order_by_value,
      });
    } else if (props.updateError) {
      snackClick("something went wrong");
    }

    if (props.bulkUploadResponse) {
      snackClick("file uploaded successfully");
      setOpenUploadModal(false);
      props.setInitialState();
      props.listProperty({
        page_num: currentPage + 1,
        per_page: perPage,
        search: search,
        order_by_field: order_by_field,
        order_by_value: order_by_value,
      });
    } else if (props.uploadError) {
      snackClick("something went wrong");
    }
  }, [props, propertyData]);

  const snackClick = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const snackClose = (event, reason) => {
    setOpen(false);
  };

  // show dropdown user info
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // hide dropdown user info
  const handleClose = () => {
    setAnchorEl(null);
  };

  // add edit property picture 1 change
  const onFileChange = (event) => {
    var fileExtension = event.target.files[0].name.split(".").pop();
    if (
      fileExtension.toLowerCase() === "png" ||
      fileExtension.toLowerCase() === "jpg" ||
      fileExtension.toLowerCase() === "jpeg"
    ) {
      setSelectedFile(event.target.files[0]);
      setSelectedFilePreview(URL.createObjectURL(event.target.files[0]));
    } else {
        snackClick("Only JPG and PNG should be required");
        event.target.value = null;
    }
  };
  // add edit property picture 2 change
  const onFileChange2 = (event) => {
    var fileExtension = event.target.files[0].name.split(".").pop();
    if (
      fileExtension.toLowerCase() === "png" ||
      fileExtension.toLowerCase() === "jpg" ||
      fileExtension.toLowerCase() === "jpeg"
    ) {
      setSelectedFile1(event.target.files[0]);
      setSelectedFile1Preview(URL.createObjectURL(event.target.files[0]));
    } else {
        snackClick("Only JPG and PNG should be required");
        event.target.value = null;
    }
  };

  // add edit property CDR FIKE change
  const onFileChange3 = (event) => {
    var fileExtension = event.target.files[0].name.split(".").pop();
    if (
      fileExtension.toLowerCase() === "pdf" 
    ) {
      setSelectedFile2(event.target.files[0]);
      setCdrFilePreview(URL.createObjectURL(event.target.files[0]));
    } else {
        snackClick("Only JPDF should be required");
        event.target.value = null;
    }
  };

  // bulk update csv
  const uploadBulk = () => {
    if (!bulkUploadFile) {
      snackClick("CSV file required");
    } else if (!bulkUploadImageFile) {
      snackClick("Image Zip required");
    } else {
      props.bulkUpload(bulkUploadFile, bulkUploadImageFile, bulkUploadCDRFile);
    }
  };

  // show delete confirmation
  const showDeleteModal = (data) => {
    setDeleteDataId(data.id);
    setOpenDeleteModal(true);
  };

  //open add property modal
  const showAddModal = () => {
    setOpenAddModal(true);
  };

  //open edit property modal
  const showEditModal = (data) => {
    setOpenEditModal(true);
    setSelectedFile(null);
    setSelectedFile1(null);
    setSelectedFile2(null);
    setEditData(data);
    setSelectedFile(data.picture_1);
    setSelectedFile1(data.picture_2);
    setSelectedFile2(data.cdr_file);
  };

  //open property view modal
//   const showViewModal = (data) => {
//     setViewData(data);
//     setOpenViewModal(true);
//   };

  //delete property api call
  const deleteProperty = () => {
    props.deleteProperty({ id: deleteDataId });
  };

  // close add property modal
  const closeAddModal = (data) => {
    setOpenAddModal(false);
  };

  //add property function call
  const onSubmit = async (values) => {
    values.picture_1 = selectedFile;
    values.picture_2 = selectedFile1;
    values.cdr_file = selectedFile2;
    values.is_img_extracted = 1;
    values.p_status = values.p_status === "1" ? true : false;
    values.cdr_date = moment().format("YYYY-MM-DD");
    props.addProperty(values);
  };

  // update property function call
  const onUpdate = (values) => {
    values.id = editData.id;
    values.picture_1 = selectedFile;
    values.p_status = values.p_status === "1" ? true : false;
    values.picture_2 = selectedFile1;
    values.cdr_file = selectedFile2;
    values.cdr_date = moment().format("YYYY-MM-DD");
    values.is_img_extracted = 1;
    props.updateProperty(values);
  };

  // status update switch call
  const handleStatusChange = (id, p_status) => {
    props.statusProperty({ id: id, p_status: p_status ? false : true });
  };

  // logout function on user info dropdown
  const logout = () => {
    handleClose();
    localStorage.removeItem("userData");
    props.history.push("/admin/login");
  };

  //change password page
  const changePassowrdLink = () => {
    props.history.push("/change-password");
  };

  const pageChange = (event, newPage) => {
    setCurrentPage(newPage);
    props.listProperty({
      page_num: newPage + 1,
      per_page: perPage,
      search: search,
      order_by_field: order_by_field,
      order_by_value: order_by_value,
    });
  };
  const onPageSizeChange = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
    props.listProperty({
      page_num: currentPage + 1,
      per_page: parseInt(event.target.value, 10),
      search: search,
      order_by_field: order_by_field,
      order_by_value: order_by_value,
    });
  };

  const onSearch = (event) => {
    setSearch(event);
    setCurrentPage(0);
    props.listProperty({
      page_num: 1,
      per_page: perPage,
      search: event,
      order_by_field: order_by_field,
      order_by_value: order_by_value,
    });
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

    return (
        <>
        <div className="container-fluid dashboard-page">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snackClose}
        message={message}
      />
      {props.listPending && (
        <div className="loading">
          <CircularProgress />
          <span>Loading...</span>
        </div>
      )}
      <header className="container">
        <nav className="navbar fixed-top navbar-light bg-light">
          <div className= {classes.navbarbrand}>
              <div>
            <img src={Logo} width="30" height="30" alt="" />
            </div>
            <div className={classes.material_ui_button}><Button component={ Link } to="/admin/property">Property</Button></div>
            <div ><Button component={ Link } to="/admin/user">User</Button></div>
          </div>         
          <div className="navbar-user-info float-right">
            {userData && (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <img src={userIcon} alt="userIcon" /> {userData.email}
                  <ExpandMoreIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={changePassowrdLink}>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </div>
        </nav>
      </header>
        <div className="main-content-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="mt-5">
              <AppBar position="static">
                <Toolbar>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <Search />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      value={search}
                      onChange={(e) => onSearch(e.target.value)}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </div>
                  <div className={classes.grow} />
                  <div className={classes.sectionDesktop}>
                    <Tooltip title="Create Property" placement="bottom">
                      <Fab
                        size="small"
                        variant="contained"
                        onClick={showAddModal}
                      >
                        <Add />
                      </Fab>
                    </Tooltip>
                    <Tooltip title="Bulk Upload" placement="bottom">
                      <Fab
                        size="small"
                        variant="contained"
                        style={{ marginLeft: 10 }}
                        onClick={() => setOpenUploadModal(true)}
                      >
                        <CloudUpload />
                      </Fab>
                    </Tooltip>
                  </div>
                </Toolbar>
              </AppBar>
              {propertyData && (
                <TableContainer component={Paper} className="dashboard-table">
                  <Table
                    className={classes.table}
                    aria-label="custom pagination table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {stableSort(
                        propertyData,
                        getComparator(order, orderBy)
                      ).map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.architect}</TableCell>
                            {/* <TableCell>{row.developer}</TableCell> */}
                            <TableCell>
                              <Switch
                                checked={row.p_status}
                                onChange={() =>
                                  handleStatusChange(row.id, row.p_status)
                                }
                                name="status"
                                inputProps={{ "aria-label": "Status" }}
                              />
                            </TableCell>
                            <TableCell>
                              <div
                                className="rgt-cell-inner"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  overflow: "hidden",
                                }}
                              >
                                
                                <IconButton
                                  color="primary"
                                  aria-label="Edit"
                                  style={{ margin: 3 }}
                                  onClick={() => showEditModal(row)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  color="secondary"
                                  style={{ margin: 3 }}
                                  onClick={() => showDeleteModal(row)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={5} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: totalData },
                          ]}
                          colSpan={5}
                          count={totalData}
                          rowsPerPage={perPage}
                          page={currentPage}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={pageChange}
                          onChangeRowsPerPage={onPageSizeChange}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}
            </div>

            {/* Property detial modal */}
            <Dialog
              onClose={() => setOpenViewModal(false)}
              aria-labelledby="customized-dialog-title"
              open={openViewModal}
              maxWidth={"md"}
              fullWidth={true}
              scroll={"paper"}
            >
              <DialogTitle
                onClose={() => setOpenViewModal(false)}
                className="offering-detail-title"
              >
                Property Detail
              </DialogTitle>
              <DialogContent>
                <TableContainer component={Paper}>
                  {viewData && (
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Address</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.address}</TableCell>
                          <StyledTableCell>
                            <strong>CDR Date</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.cdr_date}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Building SF / Gross Floor Area</strong>
                          </StyledTableCell>
                          <TableCell>
                            {viewData.building_grossfloor_area}
                          </TableCell>
                          <StyledTableCell>
                            <strong>Multi-family Units</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.multi_family}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Artist Studios</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.artist_studios}</TableCell>
                          <StyledTableCell>
                            <strong>Max Building Height</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.max_building_height}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Max # of Stories</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.stories}</TableCell>
                          <StyledTableCell>
                            <strong>Retail/Commercial Square Footage</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.retail_commercial_sf}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Parking Spaces </strong>
                          </StyledTableCell>
                          <TableCell>{viewData.parking_spaces}</TableCell>
                          <StyledTableCell>
                            <strong>Bike Spaces</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.bike_spaces}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Architect</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.architect}</TableCell>
                          <StyledTableCell>
                            <strong>Developer</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.developer}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Site Area</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.site_area}</TableCell>
                          <TableCell>
                            <img
                              alt=""
                              src={viewData.picture_1}
                              style={{ width: "100px" }}
                            />
                          </TableCell>
                          <TableCell>
                            <img
                              alt=""
                              src={viewData.picture_2}
                              style={{ width: "100px" }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Longitude </strong>
                          </StyledTableCell>
                          <TableCell>{viewData.longitude}</TableCell>
                          <StyledTableCell>
                            <strong>Latitude</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.latitude}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>Original File Name</strong>
                          </StyledTableCell>
                          <TableCell>{viewData.original_name}</TableCell>
                          <StyledTableCell>
                            <strong>Status</strong>
                          </StyledTableCell>
                          <TableCell>
                            {viewData.p_status ? (
                              <Chip
                                color="primary"
                                label="Active"
                                size="small"
                              />
                            ) : (
                              <Chip
                                color="secondary"
                                label="Deactive"
                                size="small"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <strong>CDR File</strong>
                          </StyledTableCell>
                          <TableCell>
                            {viewData.cdr_file &&
                            <a href={viewData.cdr_file}
                                  rel="noopener noreferrer"
                                  style={{fontWeight: 'bold',display: 'block'}}
                                  target="_blank">View</a>
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </DialogContent>
            </Dialog>

            {/* bulk update csv modal */}
            <Dialog
              onClose={() => setOpenUploadModal(false)}
              aria-labelledby="customized-dialog-title"
              open={openUploadModal}
              maxWidth={"sm"}
              fullWidth={true}
              scroll={"paper"}
            >
              <DialogTitle
                onClose={() => setOpenUploadModal(false)}
                className="offering-detail-title"
              >
                Bulk Upload
              </DialogTitle>
              <DialogContent>
                <div className="form-group">
                  <label for="csv" className="text-dark">
                    Upload CSV<span className="error">*</span>:
                  </label>
                  <br />
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => setBulkUploadFile(e.target.files[0])}
                  />
                  <br/>
                  <a
                    href={SampleCdr}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    className="download-button"
                  >
                    Download Sample CSV{" "}
                  </a>
                </div>
                <div className="form-group">
                  <label for="zip" className="text-dark">
                    Upload Image Zip<span className="error">*</span>:
                  </label>
                  <br />
                  <input
                    type="file"
                    onChange={(e) => setBulkUploadImageFile(e.target.files[0])}
                    accept=".zip"
                  />
                  <br/>
                  <a
                    href={Images}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    className="download-button"
                  >
                  Download Sample Image Zip{" "}
                  </a>
                </div>
                <div className="form-group">
                  <label for="zip" className="text-dark">
                    Upload CDR Zip<span className="error">*</span>:
                  </label>
                  <br />
                  <input
                    type="file"
                    onChange={(e) => setBulkUploadCDRFile(e.target.files[0])}
                    accept=".zip"
                  />
                  <br/>
                  <a
                    href={SampleCDRFile}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    className="download-button"
                  >
                  Download Sample CDR Zip{" "}
                  </a>
                </div>
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                  height: "65px",
                }}
              >
                <Button
                  style={{ marginLeft: 10, marginRight: 10 }}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setOpenUploadModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!(bulkUploadFile && bulkUploadImageFile)}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={uploadBulk}
                >
                  {" "}
                  Upload{" "}
                </Button>
              </DialogActions>
            </Dialog>

            {/* delete confirmation modal */}
            <Dialog
              onClose={() => setOpenDeleteModal(false)}
              aria-labelledby="customized-dialog-title"
              open={openDeleteModal}
              maxWidth={"sm"}
              fullWidth={true}
              scroll={"paper"}
            >
              <DialogTitle
                onClose={() => setOpenDeleteModal(false)}
                className="offering-detail-title"
              >
                Confirmation
              </DialogTitle>
              <DialogContent>
                <h5>Are you sure, you want to delete this property?</h5>
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                  height: "65px",
                }}
              >
                <Button
                  style={{ marginLeft: 10, marginRight: 10 }}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: 10, marginRight: 10 }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => deleteProperty()}
                >
                  {" "}
                  Confirm{" "}
                </Button>
              </DialogActions>
            </Dialog>

            {/* edit data mdoal */}
            <Dialog
              onClose={() => setOpenEditModal(false)}
              aria-labelledby="customized-dialog-title"
              open={openEditModal}
              maxWidth={"md"}
              fullWidth={true}
              scroll={"paper"}
            >
              <DialogTitle
                onClose={() => setOpenEditModal(false)}
                className="offering-detail-title"
              >
                Update Record
              </DialogTitle>
              {editData && (
                <form onSubmit={handleSubmit(onUpdate)}>
                  <DialogContent dividers={true}>
                    <div className="col-md-12">
                      <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <input
                              type="hidden"
                              defaultValue={editData.cdr_date}
                              name="cdr_date"
                            />
                            <label for="address" className="text-dark">
                              Address<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.address}
                              name="address"
                              id="address"
                              className="form-control"
                            />
                            {errors.address && (
                              <span className="error">Address is required</span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label
                              for="building_grossfloor_area"
                              className="text-dark"
                            >
                              Building Sf<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="number"
                              maxlength="15"
                              onInput={maxLengthCheck}
                              min="0"
                              ref={register({ required: true })}
                              defaultValue={editData.building_grossfloor_area}
                              name="building_grossfloor_area"
                              id="building_grossfloor_area"
                              className="form-control"
                            />
                            {errors.building_grossfloor_area && (
                              <span className="error">
                                Building Sf is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="multi_family" className="text-dark">
                              Multi family units<span className="error">*</span>
                              :
                            </label>
                            <br />
                            <input
                              type="Number"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.multi_family}
                              name="multi_family"
                              id="multi_family"
                              className="form-control"
                            />
                            {errors.multi_family && (
                              <span className="error">
                                Multi family units is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="artist_studios" className="text-dark">
                              Artist Studios units
                              <span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.artist_studios}
                              name="artist_studios"
                              id="artist_studios"
                              className="form-control"
                            />
                            {errors.artist_studios && (
                              <span className="error">
                                Artist Studios is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="latitude" className="text-dark">
                              Latitude<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              pattern="[+-]?([0-9]*[.])?[0-9]+"
                              ref={register({ required: true })}
                              defaultValue={editData.latitude}
                              name="latitude"
                              id="latitude"
                              className="form-control"
                            />
                            {errors.latitude && (
                              <span className="error">
                                Latitude is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="longitude" className="text-dark">
                              Longitude<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              pattern="[+-]?([0-9]*[.])?[0-9]+"
                              ref={register({ required: true })}
                              defaultValue={editData.longitude}
                              name="longitude"
                              id="longitude"
                              className="form-control"
                            />
                            {errors.longitude && (
                              <span className="error">
                                Longitude is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label
                              for="max_building_height"
                              className="text-dark"
                            >
                              Max building height
                              <span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="15"
                              onInput={maxLengthCheck}
                              min="0"
                              ref={register({ required: true })}
                              defaultValue={editData.max_building_height}
                              name="max_building_height"
                              id="max_building_height"
                              className="form-control"
                            />
                            {errors.max_building_height && (
                              <span className="error">
                                Max building height is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="stories" className="text-dark">
                              Max # of stories<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="number"
                              maxlength="15"
                              onInput={maxLengthCheck}
                              min="0"
                              ref={register({ required: true })}
                              defaultValue={editData.stories}
                              name="stories"
                              id="stories"
                              className="form-control"
                            />
                            {errors.stories && (
                              <span className="error">
                                Max # of stories is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label
                              for="retail_commercial_sf"
                              className="text-dark"
                            >
                              Retail square footage
                              <span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.retail_commercial_sf}
                              name="retail_commercial_sf"
                              id="retail_commercial_sf"
                              className="form-control"
                            />
                            {errors.retail_commercial_sf && (
                              <span className="error">
                                Retail square footage is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="parking_spaces" className="text-dark">
                              Parking spaces<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="number"
                              maxlength="15"
                              onInput={maxLengthCheck}
                              min="0"
                              ref={register({ required: true })}
                              defaultValue={editData.parking_spaces}
                              name="parking_spaces"
                              id="parking_spaces"
                              className="form-control"
                            />
                            {errors.parking_spaces && (
                              <span className="error">
                                Parking spaces is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="bike_spaces" className="text-dark">
                              Bike spaces<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="number"
                              maxlength="15"
                              onInput={maxLengthCheck}
                              min="0"
                              ref={register({ required: true })}
                              defaultValue={editData.bike_spaces}
                              name="bike_spaces"
                              id="bike_spaces"
                              className="form-control"
                            />
                            {errors.bike_spaces && (
                              <span className="error">
                                Bike spaces is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="architect" className="text-dark">
                              Architect<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.architect}
                              name="architect"
                              id="architect"
                              className="form-control"
                            />
                            {errors.architect && (
                              <span className="error">
                                Architect is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="architect" className="text-dark">
                              Developer<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.developer}
                              name="developer"
                              id="developer"
                              className="form-control"
                            />
                            {errors.developer && (
                              <span className="error">
                                Developer is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="site_area" className="text-dark">
                              Site area<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="number"
                              maxlength="15"
                              onInput={maxLengthCheck}
                              min="0"
                              ref={register({ required: true })}
                              defaultValue={editData.site_area}
                              name="site_area"
                              id="site_area"
                              className="form-control"
                            />
                            {errors.site_area && (
                              <span className="error">
                                Site area is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="original_name" className="text-dark">
                              Original file name<span className="error">*</span>
                              :
                            </label>
                            <br />
                            <input
                              type="text"
                              maxlength="60"
                              ref={register({ required: true })}
                              defaultValue={editData.original_name}
                              name="original_name"
                              id="original_name"
                              className="form-control"
                            />
                            {errors.original_name && (
                              <span className="error">
                                Original file name is required
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="p_status" className="text-dark">
                              Status<span className="error">*</span>:
                            </label>
                            <br />
                            <select
                              name="p_status"
                              ref={register({ required: true })}
                              defaultValue={editData.p_status ? 1 : 2}
                              id="p_status"
                              className="form-control"
                            >
                              <option value="1">Active</option>
                              <option value="2">Deactive</option>
                            </select>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="picture_1" className="text-dark">
                              Picture 1<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="file"
                              accept="image/*"
                              onChange={onFileChange}
                              name="picture_1"
                              id="picture_1"
                              className="form-control"
                              vale={selectedFile}
                            />
                            {errors.picture_1 && (
                              <span className="error">
                                Picture 1 is required
                              </span>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <img
                            src={
                              selectedFilePreview
                                ? selectedFilePreview
                                : editData.picture_1
                            }
                            alt=""
                            style={{ width: "100px" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="picture_2" className="text-dark">
                              Picture 2<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="file"
                              accept="image/*"
                              onChange={onFileChange2}
                              name="picture_2"
                              id="picture_2"
                              className="form-control"
                              vale={selectedFile1}
                            />
                            {errors.picture_2 && (
                              <span className="error">
                                Picture 2 is required
                              </span>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <img
                            src={
                              selectedFile1Preview
                                ? selectedFile1Preview
                                : editData.picture_2
                            }
                            alt=""
                            style={{ width: "100px" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div className="form-group">
                            <label for="picture_2" className="text-dark">
                              CDR File<span className="error">*</span>:
                            </label>
                            <br />
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={onFileChange3}
                              name="cdr_file"
                              id="cdr_file"
                              className="form-control"
                              vale={selectedFile2}
                            />
                            {errors.cdr_file && (
                              <span className="error">
                                CDR file is required
                              </span>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                        {(cdrFilePreview || editData.cdr_file) &&
                          <a href={cdrFilePreview
                                ? cdrFilePreview
                                : editData.cdr_file}
                                rel="noopener noreferrer"
                                style={{marginTop: '37px',display: 'block'}}
                                target="_blank">CDR File Preview</a>
                          }
                        </Grid>
                      </Grid>
                    </div>
                  </DialogContent>
                  <DialogActions
                    style={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                      height: "65px",
                    }}
                  >
                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => setOpenEditModal(false)}
                        // disabled={submitting}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        // disabled={submitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </DialogActions>
                </form>
              )}
            </Dialog>

            {/* add property modal */}
            <Dialog
              onClose={closeAddModal}
              aria-labelledby="customized-dialog-title"
              open={openAddModal}
              maxWidth={"md"}
              fullWidth={true}
              scroll={"paper"}
            >
              <DialogTitle
                onClose={closeAddModal}
                className="offering-detail-title"
              >
                Add Record
              </DialogTitle>

              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="address" className="text-dark">
                          Address<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="address"
                          id="address"
                          className="form-control"
                        />
                        {errors.address && (
                          <span className="error">Address is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label
                          for="building_grossfloor_area"
                          className="text-dark"
                        >
                          Building Sf<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="number"
                          maxlength="15"
                          onInput={maxLengthCheck}
                          min="0"
                          ref={register({ required: true })}
                          name="building_grossfloor_area"
                          id="building_grossfloor_area"
                          className="form-control"
                        />
                        {errors.building_grossfloor_area && (
                          <span className="error">Building Sf is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="multi_family" className="text-dark">
                          Multi family units<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="Number"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="multi_family"
                          id="multi_family"
                          className="form-control"
                        />
                        {errors.multi_family && (
                          <span className="error">
                            Multi family units is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="artist_studios" className="text-dark">
                          Artist Studios units<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="artist_studios"
                          id="artist_studios"
                          className="form-control"
                        />
                        {errors.artist_studios && (
                          <span className="error">
                            Artist Studios is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="latitude" className="text-dark">
                          Latitude<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          pattern="[+-]?([0-9]*[.])?[0-9]+"
                          ref={register({ required: true })}
                          name="latitude"
                          id="latitude"
                          className="form-control"
                        />
                        {errors.latitude && (
                          <span className="error">Latitude is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="longitude" className="text-dark">
                          Longitude<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          pattern="[+-]?([0-9]*[.])?[0-9]+"
                          ref={register({ required: true })}
                          name="longitude"
                          id="longitude"
                          className="form-control"
                        />
                        {errors.longitude && (
                          <span className="error">Longitude is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="max_building_height" className="text-dark">
                          Max building height<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="15"
                          onInput={maxLengthCheck}
                          min="0"
                          ref={register({ required: true })}
                          name="max_building_height"
                          id="max_building_height"
                          className="form-control"
                        />
                        {errors.max_building_height && (
                          <span className="error">
                            Max building height is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="stories" className="text-dark">
                          Max # of stories<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="Number"
                          maxlength="15"
                          onInput={maxLengthCheck}
                          min="0"
                          ref={register({ required: true })}
                          name="stories"
                          id="stories"
                          className="form-control"
                        />
                        {errors.stories && (
                          <span className="error">
                            Max # of stories is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="retail_commercial_sf" className="text-dark">
                          Retail square footage<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="retail_commercial_sf"
                          id="retail_commercial_sf"
                          className="form-control"
                        />
                        {errors.retail_commercial_sf && (
                          <span className="error">
                            Retail square footage is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="parking_spaces" className="text-dark">
                          Parking spaces<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="number"
                          maxlength="15"
                          onInput={maxLengthCheck}
                          min="0"
                          ref={register({ required: true })}
                          name="parking_spaces"
                          id="parking_spaces"
                          className="form-control"
                        />
                        {errors.parking_spaces && (
                          <span className="error">
                            Parking spaces is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="bike_spaces" className="text-dark">
                          Bike spaces<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="number"
                          maxlength="15"
                          onInput={maxLengthCheck}
                          min="0"
                          ref={register({ required: true })}
                          name="bike_spaces"
                          id="bike_spaces"
                          className="form-control"
                        />
                        {errors.bike_spaces && (
                          <span className="error">Bike spaces is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="architect" className="text-dark">
                          Architect<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="architect"
                          id="architect"
                          className="form-control"
                        />
                        {errors.architect && (
                          <span className="error">Architect is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="developer" className="text-dark">
                          Developer<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="developer"
                          id="developer"
                          className="form-control"
                        />
                        {errors.developer && (
                          <span className="error">Developer is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="site_area" className="text-dark">
                          Site area<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="number"
                          maxlength="15"
                          onInput={maxLengthCheck}
                          min="0"
                          ref={register({ required: true })}
                          name="site_area"
                          id="site_area"
                          className="form-control"
                        />
                        {errors.site_area && (
                          <span className="error">Site area is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="original_name" className="text-dark">
                          Original file name<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="text"
                          maxlength="60"
                          ref={register({ required: true })}
                          name="original_name"
                          id="original_name"
                          className="form-control"
                        />
                        {errors.original_name && (
                          <span className="error">
                            Original file name is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="p_status" className="text-dark">
                          Status<span className="error">*</span> :
                        </label>
                        <br />
                        <select
                          name="p_status"
                          ref={register({ required: true })}
                          id="p_status"
                          className="form-control"
                        >
                          <option value="1">Active</option>
                          <option value="2">Deactive</option>
                        </select>
                        {errors.p_status && (
                          <span className="error">Status is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="picture_1" className="text-dark">
                          Picture 1<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={onFileChange}
                          ref={register({ required: true })}
                          name="picture_1"
                          id="picture_1"
                          className="form-control"
                        />
                        {errors.picture_1 && (
                          <span className="error">Picture 1 is required</span>
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="picture_2" className="text-dark">
                          Picture 2<span className="error">*</span> :
                        </label>
                        <br />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={onFileChange2}
                          ref={register({ required: true })}
                          name="picture_2"
                          id="picture_2"
                          className="form-control"
                        />
                        {errors.picture_2 && (
                          <span className="error">Picture 2 is required</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="picture_2" className="text-dark">
                          CDR File<span className="error">*</span>:
                        </label>
                        <br />
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={onFileChange3}
                          ref={register({ required: true })}
                          name="cdr_file"
                          id="cdr_file"
                          className="form-control"
                        />
                        {errors.cdr_file && (
                          <span className="error">
                            CDR file is required
                          </span>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    height: "65px",
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={reset}
                    // disabled={submitting}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    // disabled={submitting}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        </div>
      </div>
      </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    error: addPropertyError(state),
    addPropertyResponse: addPropertyResponse(state),
    pending: addPropertyPending(state),
    listError: listPropertyError(state),
    listPropertyResponse: listPropertyResponse(state),
    listPending: listPropertyPending(state),
    deleteError: deletePropertyError(state),
    deletePropertyResponse: deletePropertyResponse(state),
    deletePending: deletePropertyPending(state),
    statusError: statusPropertyError(state),
    statusPropertyResponse: statusPropertyResponse(state),
    statusPending: statusPropertyPending(state),
    updateError: updatePropertyError(state),
    updatePropertyResponse: updatePropertyResponse(state),
    updatePending: updatePropertyPending(state),
    bulkUploadError: bulkUploadError(state),
    bulkUploadResponse: bulkUploadResponse(state),
    bulkUploadPending: bulkUploadPending(state),
  });
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        addProperty: addProperty,
        bulkUpload: bulkUpload,
        listProperty: listProperty,
        statusProperty: statusProperty,
        deleteProperty: deleteProperty,
        updateProperty: updateProperty,
        setInitialState: setInitalState,
      },
      dispatch
    );
  
  export default connect(mapStateToProps, mapDispatchToProps)(User);
  


