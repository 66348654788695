import {addPropertyPending,addPropertySuccess,addPropertyError,
    listPropertyPending,listPropertySuccess,listPropertyError,
    deletePropertyPending,deletePropertySuccess,deletePropertyError,
    statusPropertyPending,statusPropertySuccess,statusPropertyError,
    updatePropertyPending,updatePropertySuccess,updatePropertyError,
    homePropertyPending,homePropertySuccess,homePropertyError,
    geoPropertyPending,geoPropertySuccess,geoPropertyError,
    listDevPending,listDevSuccess,listDevError} from './index';
import { base_url, base_version,} from './../config';

export function addProperty(data) {
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'authorization': `${localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null}`
        },
        body: form_data
    };
    return dispatch => {
        dispatch(addPropertyPending());
        fetch(`${base_url}${base_version}create-property`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error: 'Something went wrong...!'}
                throw(error);
            }
            dispatch(addPropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(addPropertyError(error));
        })
    }
}

export function listProperty(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'authorization': `${localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null}`,
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
            "Access-Control-Max-Age": "36000",
            "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(listPropertyPending());
        fetch(`${base_url}${base_version}property`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                if(res.message === 'Exception: Signature has expired' || res.message === "Exception: Not enough segments"){
                    let error = {error:'Token expired. Please Login again',status:403}
                    throw(error);
                }else{
                    let error = res
                    throw(error);
                }
            }
            dispatch(listPropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            console.log(error)
            dispatch(listPropertyError(error));
        })
    }
}

export function listDevProperty() {
    const requestOptions = {
        method: 'GET',
        headers: { "content-type": 'application/json',
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
        "Access-Control-Max-Age": "36000",
        "Access-Control-Allow-Headers": "x-requested-with, content-type" }
    };
    return dispatch => {
        dispatch(listDevPending());
        fetch(`${base_url}${base_version}ad_list`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                if(res.message === 'Exception: Signature verification failed'){
                    let error = {error:'Token expired. Please Login again',status:403}
                    throw(error);
                }else{
                    let error = {error:'Something went wrong...!',status:400}
                    throw(error);
                }
            }
            dispatch(listDevSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            console.log(error)
            dispatch(listDevError(error));
        })
    }
}

export function listHomeProperty(data) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
            "Access-Control-Max-Age": "36000",
            "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(data)};
    return dispatch => {
        dispatch(homePropertyPending());
        fetch(`${base_url}${base_version}pview`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(homePropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            console.log(error)
            dispatch(homePropertyError(error));
        })
    }
}

export function geoHomeProperty(data) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
            "Access-Control-Max-Age": "36000",
            "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(data)};
    return dispatch => {
        dispatch(geoPropertyPending());
        fetch(`${base_url}${base_version}geoapi`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(geoPropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            console.log(error)
            dispatch(geoPropertyError(error));
        })
    }
}

export function deleteProperty(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'authorization': `${localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null}`,
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
            "Access-Control-Max-Age": "36000",
            "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(deletePropertyPending());
        fetch(`${base_url}${base_version}delete`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(deletePropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(deletePropertyError(error));
        })
    }
}

export function statusProperty(data) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'authorization': `${localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null}`,
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
            "Access-Control-Max-Age": "36000",
            "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(statusPropertyPending());
        fetch(`${base_url}${base_version}propertystatus`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(statusPropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(statusPropertyError(error));
        })
    }
}

export function updateProperty(data) {
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    const requestOptions = {
        method: 'PUT',
        headers: {
            'authorization': `${localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null}`
        },
        body: form_data
    };
    return dispatch => {
        dispatch(updatePropertyPending());
        fetch(`${base_url}${base_version}update`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(updatePropertySuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(updatePropertyError(error));
        })
    }
}