import {USER_REGISTER_PENDING,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_ERROR,
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    USER_LOGIN_PENDING,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_ERROR,
    FORGOT_PENDING,
    FORGOT_SUCCESS,
    FORGOT_ERROR, 
    USER_FORGOT_PENDING,
    USER_FORGOT_SUCCESS,
    USER_FORGOT_ERROR,
    RESET_PENDING,
    RESET_SUCCESS,
    RESET_ERROR, 
    USER_RESET_PENDING,
    USER_RESET_SUCCESS,
    USER_RESET_ERROR,
    ADD_PROPERTY_PENDING,
    ADD_PROPERTY_SUCCESS,
    ADD_PROPERTY_ERROR, 
    LIST_PROPERTY_PENDING,
    LIST_PROPERTY_SUCCESS,
    LIST_PROPERTY_ERROR, 
    GEO_PROPERTY_PENDING,
    GEO_PROPERTY_SUCCESS,
    GEO_PROPERTY_ERROR, 
    HOME_PROPERTY_PENDING,
    HOME_PROPERTY_SUCCESS,
    HOME_PROPERTY_ERROR, 
    UPDATE_PROPERTY_PENDING,
    UPDATE_PROPERTY_SUCCESS,
    UPDATE_PROPERTY_ERROR, 
    DELETE_PROPERTY_PENDING,
    DELETE_PROPERTY_SUCCESS,
    DELETE_PROPERTY_ERROR, 
    STATUS_PROPERTY_PENDING,
    STATUS_PROPERTY_SUCCESS,
    STATUS_PROPERTY_ERROR, 
    UPLOAD_PENDING,
    UPLOAD_SUCCESS,
    UPLOAD_ERROR,
    BULK_UPLOAD_PENDING,
    BULK_UPLOAD_SUCCESS,
    BULK_UPLOAD_ERROR,
    UPDATE_PASSWORD_PENDING,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_ERROR,
    PROPERTY_DEV_ERROR,
    PROPERTY_DEV_PENDING,
    PROPERTY_DEV_SUCCESS,
    SET_INITIAL_STATE
} from './../actions';

const initialState = {
    pending: false,
    loginResponse: null,
    registerResponse:null,
    userResponse:null,
    uploadResponse:null,
    bulkUploadResponse:null,
    addPropertyResponse:null,
    listPropertyResponse:null,
    geoPropertyResponse:null,
    homePropertyResponse:null,
    deletePropertyResponse:null,
    statusPropertyResponse:null,
    updatePropertyResponse:null,
    updatePasswordResponse:null,
    listDevResponse:null,
    error: null
}

export function reducers(state = initialState, action) {
    switch(action.type) {
        case USER_REGISTER_PENDING: 
        return {
            ...state,
            pending: true
        }
    case USER_REGISTER_SUCCESS:
        return {
            ...state,
            pending: false,
            registerResponse: action.registerResponse
        }
    case USER_REGISTER_ERROR:
        return {
            ...state,
            pending: false,
            error: action.error
        }
        case LOGIN_PENDING: 
            return {
                ...state,
                pending: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                pending: false,
                loginResponse: action.loginResponse
            }
        case LOGIN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
            case USER_LOGIN_PENDING: 
            return {
                ...state,
                pending: true
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                pending: false,
                loginResponse: action.loginResponse
            }
        case USER_LOGIN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FORGOT_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FORGOT_SUCCESS:
            return {
                ...state,
                pending: false,
                forgotPasswordResponse: action.forgotPasswordResponse
            }
        case FORGOT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
            case USER_FORGOT_PENDING: 
            return {
                ...state,
                pending: true
            }
        case USER_FORGOT_SUCCESS:
            return {
                ...state,
                pending: false,
                forgotPasswordResponse: action.forgotPasswordResponse
            }
        case USER_FORGOT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case RESET_PENDING: 
            return {
                ...state,
                pending: true
            }
        case RESET_SUCCESS:
            return {
                ...state,
                pending: false,
                resetPasswordResponse: action.resetPasswordResponse
            }
        case RESET_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
            case USER_RESET_PENDING: 
            return {
                ...state,
                pending: true
            }
        case USER_RESET_SUCCESS:
            return {
                ...state,
                pending: false,
                resetPasswordResponse: action.resetPasswordResponse
            }
        case USER_RESET_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case ADD_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                addPropertyResponse: action.addPropertyResponse
            }
        case ADD_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case LIST_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case LIST_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                listPropertyResponse: action.listPropertyResponse
            }
        case LIST_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case HOME_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case HOME_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                homePropertyResponse: action.homePropertyResponse
            }
        case HOME_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case GEO_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case GEO_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                geoPropertyResponse: action.geoPropertyResponse
            }
        case GEO_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case DELETE_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case DELETE_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                deletePropertyResponse: action.deletePropertyResponse
            }
        case DELETE_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case STATUS_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case STATUS_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                statusPropertyResponse: action.statusPropertyResponse
            }
        case STATUS_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case UPDATE_PROPERTY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case UPDATE_PROPERTY_SUCCESS:
            return {
                ...state,
                pending: false,
                updatePropertyResponse: action.updatePropertyResponse
            }
        case UPDATE_PROPERTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case UPLOAD_PENDING: 
            return {
                ...state,
                pending: true
            }
        case UPLOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                uploadResponse: action.uploadResponse
            }
        case UPLOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case BULK_UPLOAD_PENDING: 
            return {
                ...state,
                pending: true
            }
        case BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                bulkUploadResponse: action.bulkUploadResponse
            }
        case BULK_UPLOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        
        case UPDATE_PASSWORD_PENDING: 
            return {
                ...state,
                pending: true
            }
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                pending: false,
                updatePasswordResponse: action.updatePasswordResponse
            }
        case UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROPERTY_DEV_PENDING: 
            return {
                ...state,
                pending: true
            }
        case PROPERTY_DEV_SUCCESS:
            return {
                ...state,
                pending: false,
                listDevResponse: action.listDevResponse
            }
        case PROPERTY_DEV_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SET_INITIAL_STATE:
            return {
                ...state,
                pending: false,
                loginResponse: null,
                registerResponse:null,
                userResponse:null, 
                uploadResponse:null,
                addPropertyResponse:null,
                listPropertyResponse:null,
                geoPropertyResponse:null,
                homePropertyResponse:null,
                statusPropertyResponse:null,
                deletePropertyResponse:null,
                updatePropertyResponse:null,
                bulkUploadResponse:null,
                updatePasswordResponse:null,
                error: null
            }
        default: 
            return state;
    }
}

export const getUserRegisterResponse = state => state.registerResponse;
export const getUserRegisterPending = state => state.pending;
export const getUserRegisterError = state => state.error;


export const getLoginResponse = state => state.loginResponse;
export const getLoginPending = state => state.pending;
export const getLoginError = state => state.error;


export const getUserLoginResponse = state => state.loginResponse;
export const getUserLoginPending = state => state.pending;
export const getUserLoginError = state => state.error;


export const getForgotPasswordResponse = state => state.forgotPasswordResponse;
export const getForgotPasswordPending = state => state.pending;
export const getForgotPasswordError = state => state.error;

export const usergetForgotPasswordResponse = state => state.forgotPasswordResponse;
export const usergetForgotPasswordPending = state => state.pending;
export const usergetForgotPasswordError = state => state.error;

export const getResetPasswordResponse = state => state.resetPasswordResponse;
export const getResetPasswordPending = state => state.pending;
export const getResetPasswordError = state => state.error;


export const usergetResetPasswordResponse = state => state.resetPasswordResponse;
export const usergetResetPasswordPending = state => state.pending;
export const usergetResetPasswordError = state => state.error;

export const addPropertyResponse = state => state.addPropertyResponse;
export const addPropertyPending = state => state.pending;
export const addPropertyError = state => state.error;

export const listPropertyResponse = state => state.listPropertyResponse;
export const listPropertyPending = state => state.pending;
export const listPropertyError = state => state.error;

export const geoPropertyResponse = state => state.geoPropertyResponse;
export const geoPropertyPending = state => state.pending;
export const geoPropertyError = state => state.error;

export const homePropertyResponse = state => state.homePropertyResponse;
export const homePropertyPending = state => state.pending;
export const homePropertyError = state => state.error;

export const deletePropertyResponse = state => state.deletePropertyResponse;
export const deletePropertyPending = state => state.pending;
export const deletePropertyError = state => state.error;

export const statusPropertyResponse = state => state.statusPropertyResponse;
export const statusPropertyPending = state => state.pending;
export const statusPropertyError = state => state.error;

export const updatePropertyResponse = state => state.updatePropertyResponse;
export const updatePropertyPending = state => state.pending;
export const updatePropertyError = state => state.error;

export const uploadResponse = state => state.uploadResponse;
export const uploadPending = state => state.pending;
export const uploadError = state => state.error;

export const bulkUploadResponse = state => state.bulkUploadResponse;
export const bulkUploadPending = state => state.pending;
export const bulkUploadError = state => state.error;

export const updatePasswordResponse = state => state.updatePasswordResponse;
export const updatePasswordPending = state => state.pending;
export const updatePasswordError = state => state.error;

export const listDevResponse = state => state.listDevResponse;
export const listDevPending = state => state.pending;
export const listDevError = state => state.error;

export const setInitialState = state => state