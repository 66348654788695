import React, { useEffect } from "react";
import "./ResetPasswordPage.css";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { userResetPassword } from "../../../actions/login";
import { setInitalState } from "../../../actions";
import {
  usergetResetPasswordResponse,
  usergetResetPasswordPending,
  usergetResetPasswordError,
} from "../../../reducers/index";

const ResetPasswordPage = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isLoader, setIsLoader] = React.useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      props.history.push("/home");
    }
    if (props.resetPasswordResponse) {
      setIsLoader(false);
      snackClick(props.resetPasswordResponse.message);
      setTimeout(() => {
        props.setInitialState();
        props.history.push("/");
      }, 1000);
    } else if (props.error) {
      setIsLoader(false);
      snackClick(props.error.error);
      setTimeout(() => {
        props.setInitialState();
      }, 1000);
    }
  }, [props]);

  const snackClick = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const snackClose = (event, reason) => {
    setOpen(false);
  };

  // const goToHome = () => props.history.push('/dashboard');
  const onSubmit = (data) => {
    if (data.password !== data.confirm_password) {
      setIsPasswordMatch(true);
      snackClick("New password & Confirm password not matched");
    } else {
      data.token = window.location.href.split("/").pop();
      props.userresetPassword(data);
    }
  };
  return (
    <div id="login">
      {isLoader && (
        <div className="loading">
          <CircularProgress />
          <span>Loading...</span>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snackClose}
        message={message}
      />
      <div className="container">
        <div
          id="login-row"
          className="row justify-content-center align-items-center"
        >
          <div id="login-column" className="col-md-6">
            <div id="login-box" className="col-md-12">
              <h3 className="text-center text-info mt-3">Reset Password</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="password" className="text-dark">
                    New Password:
                  </label>
                  <br />
                  <input
                    type="password"
                    autoComplete="off"
                    ref={register({ required: true })}
                    name="password"
                    id="password"
                    className="form-control"
                  />
                  {errors.password && (
                    <span className="error">Password is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="confirm_password" className="text-dark">
                    Confirm Password:
                  </label>
                  <br />
                  <input
                    type="password"
                    autoComplete="off"
                    ref={register({ required: true })}
                    name="confirm_password"
                    id="confirm_password"
                    className="form-control"
                  />
                  {errors.confirm_password && (
                    <span className="error">Confirm password is required</span>
                  )}
                  {isPasswordMatch && (
                    <span className="error">
                      Password and Confirm should be same
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-info btn-md">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: usergetResetPasswordError(state),
  resetPasswordResponse: usergetResetPasswordResponse(state),
  pending: usergetResetPasswordPending(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userResetPassword: userResetPassword,
      setInitialState: setInitalState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
