import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import {login} from '../../../actions/login';
import {getLoginError, getLoginResponse, getLoginPending} from '../../../reducers/index';
import "./Login.css";
import { setInitalState } from '../../../actions';
function LoginPage(props) {
    const { register, handleSubmit, errors  } = useForm();
    const [open, setOpen] = React.useState(false);
    const [message,setMessage] = React.useState('');   

    useEffect(() => {
        if(localStorage.getItem('userData')){
            props.history.push('/admin/dashboard')
        }
        if(props.loginResponse)
        {            
            props.setInitialState()
            localStorage.setItem('userData',JSON.stringify(props.loginResponse));
            props.history.push('/admin/dashboard')
        }else if(props.error){
            snackClick(props.error.error)            
            props.setInitialState()
        }
    }, [props])

    const snackClick = (message) => {
        setMessage(message)
        setOpen(true);
      };
    
    const snackClose = (event, reason) => {
        setOpen(false);
    };

    const onSubmit = (data) => {
        props.login(data);
    }
    const forgotLink = () => {
        props.history.push('/admin/forgot-password')
    }
    return (
        
            <div id="login">
                {
                props.pending &&
                    <div className="loading">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={snackClose}
                    message={message}
                />
                <div className="container admin_div">
                    <div id="login-row" className="row justify-content-center align-items-center">
                        <div id="login-column" className="col-md-6">
                            <div id="login-box" className="col-md-12">
                                <h3 className="text-center text-info mt-3">Admin Login</h3>                                
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label htmlFor="email" className="text-info">Email:</label><br/>
                                        <input type="email" ref={register({ required: true})}  name="email" id="email" className="form-control" />
                                        {errors.email && <span className="error">Email is required</span>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="text-info">Password:</label><br/>
                                        <input type="password" ref={register({ required: true})}  name="password" id="password" className="form-control" />
                                        {errors.password && <span className="error">Password is required</span>}
                                    </div>                                    
                                    <div className="form-group">
                                        <label htmlFor="remember-me" className="text-info"><span><input id="remember-me" name="remember-me" type="checkbox" /></span><span>Remember me</span> </label><label className="float-right forgot-link"  onClick={() => forgotLink()}>Forgot Password</label><br/>
                                        <button type="submit" className="btn btn-info btn-md" >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}


const mapStateToProps = state => ({
    error: getLoginError(state),
    loginResponse: getLoginResponse(state),
    pending: getLoginPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    login: login,
    setInitialState:setInitalState
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage );
