import React, { useEffect } from "react";
import "./../ResetPasswordPage/ResetPasswordPage.css";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userSignup } from "../../../actions/login";
import Snackbar from "@material-ui/core/Snackbar";
import {
  getUserRegisterError,
  getUserRegisterResponse,
  getUserRegisterPending,
} from "../../../reducers/index";
import { setInitalState } from "../../../actions";
const SignUp = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isPasswordMatch, setIsPasswordMatch] = React.useState(false);
 
  useEffect(() => {
    if (localStorage.getItem("userData")) {
      props.history.push("/");
    }
    if (props.registerResponse) {
      props.setInitialState();
      props.history.push("/");
    } else if (props.error) {
      snackClick(props.error.error);
      props.setInitialState();
    }
  }, [props]);
  const snackClick = (message) => {
    setMessage(message);
    setOpen(true);
  };
  const snackClose = (event, reason) => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    if(  data.password !== data.confirm_password  ){
      setIsPasswordMatch(true);
      return false;
    }
    let signupdata = 
    {
      name : data.name,
      email : data.email,
      password : data.password,      
    }
    props.userSignup(signupdata);
  };
  const loginLink = () => {
    props.history.push("/");
  };
  return (
    <div id="login">
      
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snackClose}
        message={message}
      />
      
      <div className="container">
        <div
          id="login-row"
          className="row justify-content-center align-items-center"
         >
          <div id="login-column" className="col-md-6">
            <div id="login-box" className="col-md-12">
              <h3 className="text-center text-info mt-3">Sign Up</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="name" className="text-dark">
                    Name:
                  </label>
                  <br />
                  <input
                    type="text"
                    autoComplete="off"
                    ref={register({ required: true })}
                    name="name"
                    id="name"
                    className="form-control"
                  />
                  {errors.name && (
                    <span className="error">Name is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="text-dark">
                    Email:
                  </label>
                  <br />
                  <input
                    type="email"
                    autoComplete="off"
                    ref={register({ required: true })}
                    name="email"
                    id="password"
                    className="form-control"
                  />
                  {errors.email && (
                    <span className="error">Email is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="text-dark">
                    Password:
                  </label>
                  <br />
                  <input
                    type="password"
                    autoComplete="off"
                    ref={register({ required: true })}
                    name="password"
                    id="password"                    
                    className="form-control"
                  />               
                 {errors.password && <span className="error">Password is required</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="confirm_password" className="text-dark">
                    Confirm Password:
                  </label>
                  <br />
                  <input
                    type="password"
                    autoComplete="off"
                    ref={register({ required: true})}                                
                    name="confirm_password"
                    id="confirm_password"
                    className="form-control"
                  />
                  {errors.confirm_password && (
                    <span className="error">Confirm password is required</span>
                  )}                  
                  {isPasswordMatch && (
                    <span className="error">Password and Confirm should be same</span>
                  )}
                </div>
                <div className="form-group">
                  <label
                    className="float-right"
                    onClick={() => loginLink()}
                  >
                    Back to Login
                  </label>
                  <br />
                </div>
                <div className="form-group text-center">
                  <button type="submit" className="btn btn-info btn-md">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: getUserRegisterError(state),
  registerResponse: getUserRegisterResponse(state),
  pending: getUserRegisterPending(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userSignup: userSignup,
      setInitialState: setInitalState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
