import { base_url, base_version } from '../config';
import {uploadPending, uploadSuccess, uploadError,
    bulkUploadPending, bulkUploadSuccess, bulkUploadError} from './index';

export function upload(data) {
    const formData = new FormData()
    formData.append('file', data)
    const requestOptions = {
        method: 'PUT',
        headers: {'authorization': localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null},
        body: formData
    };
    return dispatch => {
        dispatch(uploadPending());
        fetch(`${base_url}${base_version}uploadimage`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(uploadSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(uploadError(error));
        })
    }
}

export function bulkUpload(data,zip,cdr) {
    const formData = new FormData()
    formData.append('file', data)
    formData.append('zip', zip)
    formData.append('cdr', cdr)
    const requestOptions = {
        method: 'POST',
        headers: {'authorization': localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null},
        body: formData
    };
    return dispatch => {
        dispatch(bulkUploadPending());
        fetch(`${base_url}${base_version}csv`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(bulkUploadSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(bulkUploadError(error));
        })
    }
}

