import React from 'react';
import { LazyImage } from "react-lazy-images";

export const LazyImageWithBox = ({ src, alt }) => (
    <div className="bg-near-white intrinsic intrinsic--3x2">
      <LazyImage
        src={src}
        alt={alt}
        debounceDurationMs={400}
        placeholder={({ ref }) => <div ref={ref} className="intrinsic-item" />}
        actual={({ imageProps }) => (
          <img {...imageProps} className="intrinsic-item animated fadeIn" alt='loading...'/>
        )}
      />
    </div>
  );