import {updatePasswordPending,updatePasswordSuccess,updatePasswordError} from './index';
import { base_url, base_version,} from './../config';
import { sha3_512 } from 'js-sha3';

export function updatePassword(data) {
        let formData = {
            user_id:localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).user_id:null,
            n_password:sha3_512(data.new_password),
            old_password:sha3_512(data.old_password)
        }
    const requestOptions = {
        method: 'PUT',
        headers:  {
            'authorization': `${localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')).token:null}`,
            "content-type": 'application/json',
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
            "Access-Control-Max-Age": "36000",
            "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(formData)
    };
    return dispatch => {
        dispatch(updatePasswordPending());
        fetch(`${base_url}${base_version}admin/changepassword`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(updatePasswordSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(updatePasswordError(error));
        })
    }
}

