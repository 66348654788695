import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import {forgotPassword} from '../../../actions/login';
import {getForgotPasswordError, getForgotPasswordResponse, getForgotPasswordPending} from '../../../reducers/index';


import "./ForgotPassword.css";
import { setInitalState } from '../../../actions';
function ForgotPasswordPage(props) {
    const { register, handleSubmit, errors,reset  } = useForm();
    const [open, setOpen] = React.useState(false);
    const [message,setMessage] = React.useState('');   

    useEffect(() => {
        if(localStorage.getItem('userData')){
            props.history.push('/admin/dashboard')
        }
        if(props.ForgotPasswordResponse)
        {    
            reset()
            snackClick(props.ForgotPasswordResponse.message)         
            props.setInitialState()
        }else if(props.error){
            snackClick(props.error.error)            
            props.setInitialState()
        }
    }, [props])

    const snackClick = (message) => {
        setMessage(message)
        setOpen(true);
      };
    
    const snackClose = (event, reason) => {
        setOpen(false);
    };

    // const goToHome = () => props.history.push('/dashboard');
    const onSubmit = (data) => {
        props.forgotPassword(data);
    } 

    const loginLink = () => {
        props.history.push('/admin/login')
    }
    return (
        
            <div id="login">
                {
                props.pending &&
                    <div className="loading">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={snackClose}
                    message={message}
                />
                <div className="container">
                    <div id="login-row" className="row justify-content-center align-items-center">
                        <div id="login-column" className="col-md-6">
                            <div id="login-box" className="col-md-12">
                                <h3 className="text-center text-info mt-3">Forgot Password</h3>                                
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label for="email" className="text-info">Email:</label><br/>
                                        <input type="email" ref={register({ required: true})}  name="email" id="email" className="form-control" />
                                        {errors.email && <span className="error">Email is required</span>}
                                    </div>
                                    <div className="form-group">
                                        <label className="float-right" style={{cursor:'pointer'}} onClick={() => loginLink()}>Back to Login</label><br/>
                                        <button type="submit" className="btn btn-info btn-md" >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}


const mapStateToProps = state => ({
    error: getForgotPasswordError(state),
    ForgotPasswordResponse: getForgotPasswordResponse(state),
    pending: getForgotPasswordPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    forgotPassword: forgotPassword,
    setInitialState:setInitalState
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordPage );
