import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LoginPage from "./containers/Admin/Login/Login";
import DashboardPage from "./containers/Admin/Dashboard/Dashboard";
import ChangePasswordPage from "./containers/Admin/ChangePassword/ChangePassword";
import ForgotPassword from './containers/Admin/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/Admin/ResetPassword/ResetPassword';
import HomeOne from './containers/User/HomeOne/HomeOne';
import HomeLogin from './containers/User/Login/Login';
import ForgotPasswordHome from './containers/User/ForgotPasswordHome/ForgotPasswordHome';
import ResetPasswordPage from './containers/User/ResetPasswordPage/ResetPasswordPage';
import SignUp from './containers/User/SignUp/SignUp';
import User from './containers/User/Pages/User';
import Property from './containers/User/Pages/Property';
import UserChangePassword from './containers/User/UserChangePassword/UserChangePassword';



class App extends Component {
    render() {
        return (
            <div style={{height: '100%'}}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/admin/login" component={LoginPage}/>                
                        <Route path="/admin/dashboard" component={DashboardPage}/>
                        <Route path="/change-password" component={ChangePasswordPage}/>
                        <Route path="/admin/forgot-password" component={ForgotPassword}/>
                        <Route path="/resetpassword" component={ResetPassword}/>
                        <Route exact={true} path="/" component={HomeLogin} />  {/* the file name of HomeLogin is Login.js  */}
                        <Route path="/forgot-password" component={ForgotPasswordHome} />
                        <Route path="/home" component={HomeOne}/>
                        <Route path="/userchangepassword" component={UserChangePassword} /> 
                        <Route path="/reset-password" component={ResetPasswordPage} />
                        <Route path="/signup" component={SignUp} />
                        <Route path="/admin/user" component={User} />
                        <Route path="/admin/property" component={Property} />
                        <Route path="/property" component={Property} />                                          
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
