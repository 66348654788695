// import React from 'react';
import React, { useEffect, useState } from "react";
import "./../../Admin/ChangePassword/ChangePassword.css";
import Logo from "../../../assets/img/bs.png";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import { useForm } from "react-hook-form";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, IconButton } from "@material-ui/core";
import userIcon from "../../../assets/img/user_icon.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { updatePassword } from "../../../actions/updatePassword";
import { setInitalState } from "../../../actions/index";
import {
  updatePasswordPending,
  updatePasswordResponse,
  updatePasswordError,
} from "../../../reducers/index";
import { ArrowBack } from "@material-ui/icons";

let userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;
function ChangePasswordPage(props) {
  const { register, handleSubmit, errors, reset } = useForm();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState("");

  //use effect
  useEffect(() => {
    if (props.udpatePasswordResponse) {
      snackClick("Password Updated Successfully");
      setTimeout(() => {
        reset();
        props.setInitialState();
        localStorage.removeItem("userData");
        props.history.push("/admin/login");
      }, 1000);
    } else if (props.error) {
      snackClick("Something went wrong");
    }
  }, [props]);

  const snackClick = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const snackClose = (event, reason) => {
    setOpen(false);
  };

  // show dropdown user info
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // hide dropdown user info
  const handleClose = () => {
    setAnchorEl(null);
  };

  //add property function call
  const onSubmit = async (values) => {
    if (values.new_password === values.old_password) {
      snackClick("New password & Old password could not be same");
    } else if (values.new_password !== values.confirm_password) {
      snackClick("New password & Confirm password not matched");
    } else {
      props.updatePassword(values);
    }
  };

  // logout function on user info dropdown
  const logout = () => {
    handleClose();
    localStorage.removeItem("userData");
    props.history.push("/");
    
  };
  // goto dashobard
  const dashboard = () => {
    props.history.push("/");
  };

  const back = () => {
    props.history.push("/");
  };

  return (
    <div className="container-fluid dashboard-page">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snackClose}
        message={message}
      />
      {props.pending && (
        <div className="loading">
          <CircularProgress />
          <span>Loading...</span>
        </div>
      )}
      <header className="container">
        <nav className="navbar fixed-top navbar-light bg-light">
          <div className="navbar-brand" onClick={dashboard}>
            <img src={Logo} width="30" height="30" alt="" />
          </div>
          <div className="navbar-user-info float-right">
            {userData && (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <img src={userIcon} alt="userIcon" /> {userData.email}
                  <ExpandMoreIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Change Password</MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </div>
        </nav>
      </header>
      <div className="main-content-dashboard">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    <IconButton aria-label="Back" onClick={() => back()}>
                      <ArrowBack />
                    </IconButton>
                    &nbsp;&nbsp;Update password
                  </Typography>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <div className="form-group">
                        <label htmlFor="old_password" className="text-dark">
                          Old Password:
                        </label>
                        <br />
                        <input
                          type="password"
                          autoComplete="off"
                          ref={register({ required: true })}
                          name="old_password"
                          id="old_password"
                          className="form-control"
                        />
                        {errors.old_password && (
                          <span className="error">
                            Old Password is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group">
                        <label htmlFor="new_password" className="text-dark">
                          New Password:
                        </label>
                        <br />
                        <input
                          type="password"
                          autoComplete="off"
                          ref={register({ required: true })}
                          name="new_password"
                          id="new_password"
                          className="form-control"
                        />
                        {errors.new_password && (
                          <span className="error">
                            New Password is required
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group">
                        <label htmlFor="confirm_password" className="text-dark">
                          Confirm Password:
                        </label>
                        <br />
                        <input
                          type="password"
                          autoComplete="off"
                          ref={register({ required: true })}
                          name="confirm_password"
                          id="confirm_password"
                          className="form-control"
                        />
                        {errors.confirm_password && (
                          <span className="error">
                            Confirm Password required
                          </span>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions >
                  <Grid container alignItems="flex-start"
                   spacing={2}
                   className="reset-button"
                    >
                    <Grid item>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={reset}
                        // disabled={submitting}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        // disabled={submitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  error: updatePasswordError(state),
  udpatePasswordResponse: updatePasswordResponse(state),
  pending: updatePasswordPending(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePassword: updatePassword,
      setInitialState: setInitalState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
