import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Userlogin } from "../../../actions/login";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getUserLoginError,
  getUserLoginResponse,
  getUserLoginPending,
} from "../../../reducers/index";
import { setInitalState } from "../../../actions";
const useStyle = makeStyles({
  singnup_link: {
    fontSize: "14px",
    textAlign: "center",
    color: "#17a2b8",
    paddingBottom: "20px",
  }
});

const HomeLogin = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isLoader, setIsLoader] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      props.history.push("/home");
    }
    if (props.loginResponse) {
      setIsLoader(false);
      props.setInitialState();
      localStorage.setItem("userData", JSON.stringify(props.loginResponse));
      props.history.push("/home");
    } else if (props.error) {
      setIsLoader(false);
      console.log("error", props.error.error);
      snackClick(props.error.error);
      setTimeout(() => {
        props.setInitialState();
      }, 1000);
    }
  }, [props]);

  const snackClick = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const snackClose = (event, reason) => {
    setOpen(false);
  };

  // const goToHome = () => props.history.push('/dashboard');
  const onSubmit = (data) => {
    setIsLoader(true);
    props.Userlogin(data);
  };

  const forgotLink = () => {
    props.history.push("/forgot-password");
  };
  const classes = useStyle();
  return (
    <div id="login">
      {isLoader && (
        <div className="loading">
          <CircularProgress />
          <span>Loading...</span>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snackClose}
        message={message}
      />
      <div className="container">
        <div
          id="login-row"
          className="row justify-content-center align-items-center"
        >
          <div id="login-column" className="col-md-6">
            <div id="login-box" className="col-md-12">
              <h3 className="text-center text-info mt-3">Login</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="email" className="text-info">
                   Email:
                  </label>
                  <br />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    ref={register({ required: true })}
                  />
                  {errors.email && (
                    <span className="error">Email is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="text-info">
                    Password:
                  </label>
                  <br />
                  <input
                    type="password"
                    name="password"
                    id="password"
                    ref={register({ required: true,
                     })}
                    className="form-control"
                  />
                  {errors.password && (
                    <span className="error">Password is required</span>
                  )} 
                </div>

                <div className="form-group">
                  <label htmlFor="remember-me" className="text-info">
                    <span>
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                      />
                    </span>
                    &nbsp;&nbsp;
                    <span>Remember me</span> 
                  </label>
                  <label
                    className="float-right forgot-link"
                    onClick={() => forgotLink()}
                  >
                    Forgot Password
                  </label>
                  <br />
                  <button type="submit" className="btn btn-info btn-md">
                    Submit
                  </button>
                </div>
                <div className={classes.singnup_link}>
                  <div>
                    <p>
                      Don't have an account <Link to="/signup"> Sign Up</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: getUserLoginError(state),
  loginResponse: getUserLoginResponse(state),
  pending: getUserLoginPending(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      Userlogin: Userlogin,
      setInitialState: setInitalState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeLogin);
