export const USER_REGISTER_PENDING = 'USER_REGISTER_PENDING';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';


export const USER_LOGIN_PENDING = 'LOGIN_PENDING';
export const USER_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'LOGIN_ERROR';

export const FORGOT_PENDING = 'FORGOT_PENDING';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';


export const USER_FORGOT_PENDING = 'FORGOT_PENDING';
export const USER_FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const USER_FORGOT_ERROR = 'FORGOT_ERROR';


export const RESET_PENDING = 'RESET_PENDING';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';

export const USER_RESET_PENDING = 'USER_RESET_PENDING';
export const USER_RESET_SUCCESS = 'USER_RESET_SUCCESS';
export const USER_RESET_ERROR = 'USER_RESET_ERROR';


export const ADD_PROPERTY_PENDING = 'ADD_PROPERTY_PENDING';
export const ADD_PROPERTY_SUCCESS = 'ADD_PROPERTY_SUCCESS';
export const ADD_PROPERTY_ERROR = 'ADD_PROPERTY_ERROR';

export const LIST_PROPERTY_PENDING = 'LIST_PROPERTY_PENDING';
export const LIST_PROPERTY_SUCCESS = 'LIST_PROPERTY_SUCCESS';
export const LIST_PROPERTY_ERROR = 'LIST_PROPERTY_ERROR';

export const HOME_PROPERTY_PENDING = 'HOME_PROPERTY_PENDING';
export const HOME_PROPERTY_SUCCESS = 'HOME_PROPERTY_SUCCESS';
export const HOME_PROPERTY_ERROR = 'HOME_PROPERTY_ERROR';

export const GEO_PROPERTY_PENDING = 'GEO_PROPERTY_PENDING';
export const GEO_PROPERTY_SUCCESS = 'GEO_PROPERTY_SUCCESS';
export const GEO_PROPERTY_ERROR = 'GEO_PROPERTY_ERROR';

export const DELETE_PROPERTY_PENDING = 'DELETE_PROPERTY_PENDING';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_ERROR = 'DELETE_PROPERTY_ERROR';

export const STATUS_PROPERTY_PENDING = 'STATUS_PROPERTY_PENDING';
export const STATUS_PROPERTY_SUCCESS = 'STATUS_PROPERTY_SUCCESS';
export const STATUS_PROPERTY_ERROR = 'STATUS_PROPERTY_ERROR';

export const UPDATE_PROPERTY_PENDING = 'UPDATE_PROPERTY_PENDING';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_ERROR = 'UPDATE_PROPERTY_ERROR';

export const UPLOAD_PENDING = 'UPLOAD_PENDING';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

export const BULK_UPLOAD_PENDING = 'BULK_UPLOAD_PENDING';
export const BULK_UPLOAD_SUCCESS = 'BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_ERROR = 'BULK_UPLOAD_ERROR';

export const UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const PROPERTY_DEV_PENDING = 'PROPERTY_DEV_PENDING';
export const PROPERTY_DEV_SUCCESS = 'PROPERTY_DEV_SUCCESS';
export const PROPERTY_DEV_ERROR = 'PROPERTY_DEV_ERROR';

export const SET_INITIAL_STATE = "SET_INITIAL_STATE";


export function UsersignupPending() {
    return {
        type: USER_REGISTER_PENDING
    }
}

export function UsersignupSuccess(registerResponse) {
    return {
        type: USER_REGISTER_SUCCESS,
        registerResponse: registerResponse
    }
}

export function UsersignupError(error) {
    return {
        type: USER_REGISTER_ERROR,
        error: error
    }
}


export function loginPending() {
    return {
        type: LOGIN_PENDING
    }
}

export function loginSuccess(loginResponse) {
    return {
        type: LOGIN_SUCCESS,
        loginResponse: loginResponse
    }
}

export function loginError(error) {
    return {
        type: LOGIN_ERROR,
        error: error
    }
}

export function UserloginPending() {
    return {
        type: USER_LOGIN_PENDING
    }
}

export function UserloginSuccess(loginResponse) {
    return {
        type: USER_LOGIN_SUCCESS,
        loginResponse: loginResponse
    }
}

export function UserloginError(error) {
    return {
        type: USER_LOGIN_ERROR,
        error: error
    }
}
export function forgotPasswordPending() {
    return {
        type: FORGOT_PENDING
    }
}

export function forgotPasswordSuccess(forgotPasswordResponse) {
    return {
        type: FORGOT_SUCCESS,
        forgotPasswordResponse: forgotPasswordResponse
    }
}

export function forgotPasswordError(error) {
    return {
        type: FORGOT_ERROR,
        error: error
    }
}


export function userforgotPasswordPending() {
    return {
        type: USER_FORGOT_PENDING
    }
}

export function userforgotPasswordSuccess(forgotPasswordResponse) {
    return {
        type: USER_FORGOT_SUCCESS,
        forgotPasswordResponse: forgotPasswordResponse
    }
}

export function userforgotPasswordError(error) {
    return {
        type: USER_FORGOT_ERROR,
        error: error
    }
}


export function resetPasswordPending() {
    return {
        type: RESET_PENDING
    }
}

export function resetPasswordSuccess(resetPasswordResponse) {
    return {
        type: RESET_SUCCESS,
        resetPasswordResponse: resetPasswordResponse
    }
}

export function resetPasswordError(error) {
    return {
        type: RESET_ERROR,
        error: error
    }
}


export function userResetPasswordPending() {
    return {
        type: USER_RESET_PENDING
    }
}

export function userResetPasswordSuccess(resetPasswordResponse) {
    return {
        type: USER_RESET_SUCCESS,
        resetPasswordResponse: resetPasswordResponse
    }
}

export function userResetPasswordError(error) {
    return {
        type: USER_RESET_ERROR,
        error: error
    }
}


export function addPropertyPending() {
    return {
        type: ADD_PROPERTY_PENDING
    }
}

export function addPropertySuccess(addPropertyResponse) {
    return {
        type: ADD_PROPERTY_SUCCESS,
        addPropertyResponse: addPropertyResponse
    }
}

export function addPropertyError(error) {
    return {
        type: ADD_PROPERTY_ERROR,
        error: error
    }
}

export function listPropertyPending() {
    return {
        type: LIST_PROPERTY_PENDING
    }
}

export function listPropertySuccess(listPropertyResponse) {
    return {
        type: LIST_PROPERTY_SUCCESS,
        listPropertyResponse: listPropertyResponse
    }
}

export function listPropertyError(error) {
    return {
        type: LIST_PROPERTY_ERROR,
        error: error
    }
}

export function listDevPending() {
    return {
        type: PROPERTY_DEV_PENDING
    }
}

export function listDevSuccess(listDevResponse) {
    return {
        type: PROPERTY_DEV_SUCCESS,
        listDevResponse: listDevResponse
    }
}

export function listDevError(error) {
    return {
        type: PROPERTY_DEV_ERROR,
        error: error
    }
}

export function geoPropertyPending() {
    return {
        type: GEO_PROPERTY_PENDING
    }
}

export function geoPropertySuccess(geoPropertyResponse) {
    return {
        type: GEO_PROPERTY_SUCCESS,
        geoPropertyResponse: geoPropertyResponse
    }
}

export function geoPropertyError(error) {
    return {
        type: GEO_PROPERTY_ERROR,
        error: error
    }
}

export function homePropertyPending() {
    return {
        type: HOME_PROPERTY_PENDING
    }
}

export function homePropertySuccess(homePropertyResponse) {
    return {
        type: HOME_PROPERTY_SUCCESS,
        homePropertyResponse: homePropertyResponse
    }
}

export function homePropertyError(error) {
    return {
        type: HOME_PROPERTY_ERROR,
        error: error
    }
}

export function deletePropertyPending() {
    return {
        type: DELETE_PROPERTY_PENDING
    }
}

export function deletePropertySuccess(deletePropertyResponse) {
    return {
        type: DELETE_PROPERTY_SUCCESS,
        deletePropertyResponse: deletePropertyResponse
    }
}

export function deletePropertyError(error) {
    return {
        type: DELETE_PROPERTY_ERROR,
        error: error
    }
}

export function statusPropertyPending() {
    return {
        type: STATUS_PROPERTY_PENDING
    }
}

export function statusPropertySuccess(statusPropertyResponse) {
    return {
        type: STATUS_PROPERTY_SUCCESS,
        statusPropertyResponse: statusPropertyResponse
    }
}

export function statusPropertyError(error) {
    return {
        type: STATUS_PROPERTY_ERROR,
        error: error
    }
}

export function updatePropertyPending() {
    return {
        type: UPDATE_PROPERTY_PENDING
    }
}

export function updatePropertySuccess(updatePropertyResponse) {
    return {
        type: UPDATE_PROPERTY_SUCCESS,
        updatePropertyResponse: updatePropertyResponse
    }
}

export function updatePropertyError(error) {
    return {
        type: UPDATE_PROPERTY_ERROR,
        error: error
    }
}

export function uploadPending() {
    return {
        type: UPLOAD_PENDING
    }
}

export function uploadSuccess(uploadResponse) {
    return {
        type: UPLOAD_SUCCESS,
        uploadResponse: uploadResponse
    }
}

export function uploadError(error) {
    return {
        type: UPLOAD_ERROR,
        error: error
    }
}

export function bulkUploadPending() {
    return {
        type: BULK_UPLOAD_PENDING
    }
}

export function bulkUploadSuccess(bulkUploadResponse) {
    return {
        type: BULK_UPLOAD_SUCCESS,
        bulkUploadResponse: bulkUploadResponse
    }
}

export function bulkUploadError(error) {
    return {
        type: BULK_UPLOAD_ERROR,
        error: error
    }
}

export function updatePasswordPending() {
    return {
        type: UPDATE_PASSWORD_PENDING
    }
}

export function updatePasswordSuccess(updatePasswordResponse) {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        updatePasswordResponse: updatePasswordResponse
    }
}

export function updatePasswordError(error) {
    return {
        type: UPDATE_PASSWORD_ERROR,
        error: error
    }
}

export function setInitalState(){
    return{
        type:SET_INITIAL_STATE
    }
}
