import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import Freedraw, { ALL, NONE } from "react-leaflet-freedraw";
import "./HomeOne.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import userIcon from "../../../assets/img/user_icon.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Polygon } from "react-leaflet";
import Logo from "../../../assets/img/bs.png";
import ProPic from "../../../assets/img/product.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  listHomeProperty,
  geoHomeProperty,
  listDevProperty,
} from "../../../actions/property";
import { setInitalState } from "../../../actions/index";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  homePropertyResponse,
  homePropertyError,
  homePropertyPending,
  geoPropertyResponse,
  geoPropertyError,
  geoPropertyPending,
  listDevResponse,
  listDevError,
  listDevPending,
} from "../../../reducers/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Box,
  TextField,
  Card,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Img } from "react-image";
import L from "leaflet";
// import { LazyImage } from "react-lazy-images";
import { LazyImageWithBox } from "./LazyImageWithBox";
// import FreeDraw from 'leaflet-freedraw';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
  viewbuttion: {
    fontSize: "12px",
    marginBottom: "-4px",
  },
  mappointer: {
    cursor: "auto",
  },
});

var points = [];

function PointMarker(props) {
  const markerRef = useRef(null);
  const classes = useStyles();
  const { index, center, item, openPopup, onMarkerClick, icon } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markerIcon1 = new L.Icon({
    iconUrl: require("../../../assets/img/icon.svg"),
    iconSize: [35, 45],
  });
  const markerIcon2 = new L.Icon({
    iconUrl: require("../../../assets/img/icon2.svg"),
    iconSize: [35, 45],
  });
  const markerIcon3 = new L.Icon({
    iconUrl: require("../../../assets/img/icon3.svg"),
    iconSize: [35, 45],
  });
  const markerIcon4 = new L.Icon({
    iconUrl: require("../../../assets/img/icon4.svg"),
    iconSize: [35, 45],
  });
  const markerIcon5 = new L.Icon({
    iconUrl: require("../../../assets/img/icon5.svg"),
    iconSize: [35, 45],
  });
  const markerIcon6 = new L.Icon({
    iconUrl: require("../../../assets/img/icon6.svg"),
    iconSize: [35, 45],
  });
  const markerIcon7 = new L.Icon({
    iconUrl: require("../../../assets/img/icon7.svg"),
    iconSize: [35, 45],
  });
  const markerIcon8 = new L.Icon({
    iconUrl: require("../../../assets/img/icon8.svg"),
    iconSize: [35, 45],
  });
  const markerIcon9 = new L.Icon({
    iconUrl: require("../../../assets/img/icon9.svg"),
    iconSize: [35, 45],
  });
  useEffect(() => {
    if (openPopup) markerRef.current.openPopup();
  }, [openPopup]);
  let popup = [];
  if (index < 9) {
    popup.push("popup_mes");
  } else {
    popup.push("popup_default");
  }
  return (
    <>
      <Marker
        ref={markerRef}
        key={index}
        icon={
          index === 0
            ? markerIcon1
            : index === 1
            ? markerIcon2
            : index === 2
            ? markerIcon3
            : index === 3
            ? markerIcon4
            : index === 4
            ? markerIcon5
            : index === 5
            ? markerIcon6
            : index === 6
            ? markerIcon7
            : index === 7
            ? markerIcon8
            : index === 8
            ? markerIcon9
            : icon
        }
        eventHandlers={{
          click: (e) => {
            onMarkerClick(item, index);
          },
        }}
        name={item.original_name}
        position={center}
      >
        <Popup className={popup.join(" ")}>
          <div className="marker-popup">
            <Img
              className="card-img-top img-fluid card-image-res mt-2"
              src={[item.picture_1, ProPic]}
            />
            {item.address && item.address.trim() !== "" && (
              <div className="d-flex mt-2">
                <p className="card-title mr-2">
                  <b>Address:</b> &nbsp; &nbsp;
                </p>
                <p className="card-title address">{item.address}</p>
              </div>
            )}
            {item.architect && item.architect.trim() !== "" && (
              <div className="d-flex">
                <p className="card-title mr-2">
                  <b>-</b> &nbsp;
                </p>
                <p className="card-title">{item.architect}</p>
              </div>
            )}
            {item.developer && item.developer.trim() !== "" && (
              <div className="d-flex">
                <p className="card-title mr-2">
                  <b>-</b> &nbsp;
                </p>
                <p className="card-title">{item.developer}</p>
              </div>
            )}
            <p className="card-text popup-extra-data">
              {item.multi_family} Units // {item.building_grossfloor_area} GSF
            </p>
            <div className="d-flex justify-content-between button-group">
              <div>
                {item.cdr_file && (
                  <a
                    href={item.cdr_file}
                    className="file-preview mt-1"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CDR File
                  </a>
                )}
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={handleShow}
                  className={classes.viewbuttion}
                >
                  view more
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header>
                    <Modal.Title>More Detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex justify-content-around">
                      <div className="model-item-detail">
                        <Card className="model-card">
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <div className="model-card-box">
                                <div className="w-100 mb-3 model-card-box-img">
                                  <Img
                                    className="card-img-top img-fluid model-card-box-img"
                                    src={[item.picture_1, ProPic]}
                                  />
                                </div>
                                <div className="model_data">
                                  <b>Address-</b> {item.address}
                                </div>
                                <div className="model_data">
                                  <b>Architect-</b> {item.architect}
                                </div>
                                <div className="model_data">
                                  <b>Bike Space-</b> {item.bike_spaces}
                                </div>
                                <div className="model_data">
                                  <b> Building Grossfloor Area-</b>{" "}
                                  {item.building_grossfloor_area}
                                </div>
                                <div className="model_data">
                                  <b>Developer-</b> {item.developer}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <div className="model-card-box">
                                <div className="w-100 mb-3 model-card-box-img">
                                  <Img
                                    className="card-img-top img-fluid model-card-box-img"
                                    src={[item.picture_2, ProPic]}
                                  />
                                </div>
                                <div className="model_data">
                                  <b> Max Building Height-</b>{" "}
                                  {item.max_building_height}
                                </div>
                                <div className="model_data">
                                  <b>Multi Family-</b> {item.multi_family}
                                </div>
                                <div className="model_data">
                                  <b>Parking Area-</b>{" "}
                                  {item.p_status ? "Yes" : "No"}
                                </div>
                                <div className="model_data">
                                  <b>Parking Space-</b> {item.parking_spaces}
                                </div>
                                <div className="model_data">
                                  <b>Site Area-</b> {item.site_area}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </Popup>
      </Marker>
    </>
  );
}
function HomeOnePage(props) {
  const [propertyShow, setPropertyShow] = useState(false);
  const classes = useStyles();
  const [propertyData, setPropertyData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [center, setCenter] = useState([37.0902, 95.7129]);
  const [open, setOpen] = React.useState(false);
  const [coords, setCoords] = useState([]);
  const [multiFamilyTo, setMultiFamilyTo] = useState("");
  const [multiFamilyFrom, setMultiFamilyFrom] = useState("");
  const [fromDate, setFromDate] = useState(moment("01/01/2019"));
  const [toDate, setToDate] = useState(moment());
  const [architect, setArchitect] = useState("");
  const [developer, setDeveloper] = useState("");
  const [arcData, setArcData] = useState([]);
  const [devData, setDevData] = useState([]);
  const [message, setMessage] = useState("");
  const [noOfUnits, setNoOfUnits] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [userData1, setUserData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [freedrawVisible, setFreedrawVisible] = useState(NONE);
  const freedrawRef = useRef(null);
  const mapRef = React.createRef();

  const strockOptions = { color: '#3f51b5' }

  useEffect(() => {
    let userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
    setUserData(userData);
    if (!propertyShow) {
      setPropertyShow(true);
      setTimeout(() => {
        props.listProperty({
          from_date: "",
          to_date: "",
          architect: architect,
          developer: developer,
          multi_1: "",
          multi_2: "",
        });
        props.listDevProperty();
      }, 1000);
    }

    if (props.listPropertyResponse) {
      if (props.listPropertyResponse.length > 0) {
        let no = 0;
        for (let item of props.listPropertyResponse[0]) {
          no += Number(item.multi_family);
          if (item.latitude !== "" && !Number.isNaN(item.latitude)) {
            let obj = [parseFloat(item.latitude), parseFloat(item.longitude)];
            points = [...points, obj];
          }
        }
        setNoOfUnits(no);
        if (points[0]) {
          setCenter([points[0][0], points[0][1]]);
        }
        setPropertyData(props.listPropertyResponse[0]);
        setFilterData(props.listPropertyResponse[0]);
      }
    } else if (props.listError) {
      if (props.listError.status === 403) {
        snackClick(props.listError.error);
        props.setInitialState();
        localStorage.removeItem("userData");
        props.history.push("/");
      }
    }
    if (props.listDevResponse) {
      if (props.listDevResponse.length > 0) {
        let dvList = props.listDevResponse[0];
        let devsList = [];
        let arcList = [];
        for (let item of dvList) {
          devsList.push(item.developer);
          arcList.push(item.architect);
        }
        setDevData([...new Set(devsList)]);
        setArcData([...new Set(arcList)]);
      }
    }

    if (props.geoPropertyResponse) {
      if (props.geoPropertyResponse.length > 0) {
        setPropertyData(props.geoPropertyResponse[0]);
        setFilterData(props.geoPropertyResponse[0]);
        let no = 0;
        for (let item of props.geoPropertyResponse[0]) {
          no += Number(item.multi_family);
        }
        setNoOfUnits(no);
      }
    } else if (props.geoError) {
      snackClick("No Data found");
    }
  }, [props, propertyData]);
  const changePassowrdLink = () => {
    props.history.push("/userchangepassword");
  };
  useEffect(() => {
    onSearch();
  }, [fromDate, toDate, multiFamilyTo, multiFamilyFrom, developer, architect]);
  const snackClick = (message) => {
    setMessage(message);
    setOpen(true);
  };
  const snackClose = (event, reason) => {
    setOpen(false);
  };
  const handle_Close = () => {
    setAnchorEl(null);
  };
  const onMarkerClick = (props, index) => {
    window.location.href = "#" + props.id;
    setSelectedPlace(props);
    setCenter([props.latitude, props.longitude]);
    if (props.latitude !== "" && !Number.isNaN(props.latitude)) {
      setSelectedIndex(index);
    } else {
      snackClick("latitude or longitude is no correct");
    }
  };
  const onSearch = () => {
    if (Number(multiFamilyFrom) !== 0 && Number(multiFamilyTo) !== 0) {
      if (Number(multiFamilyFrom) > Number(multiFamilyTo)) {
        snackClick(
          "Multi family unit to should be greater than Multi family unit from"
        );
        return false;
      }
    }
    let fromDate1 = fromDate
      ? moment(moment(fromDate).format("MM/01/YYYY"))
      : "";
    let toDate1 = toDate ? moment(moment(toDate).format("MM/31/YYYY")) : "";
    props.setInitialState();
    setCoords([]);
    setPropertyData([]);
    setSelectedPlace({});
    props.listProperty({
      from_date: fromDate1,
      to_date: toDate1,
      architect: architect,
      developer: developer,
      multi_1:
        multiFamilyTo === ""
          ? multiFamilyFrom
          : multiFamilyFrom === ""
          ? "0"
          : multiFamilyFrom,
      multi_2:
        multiFamilyFrom === ""
          ? multiFamilyTo
          : multiFamilyTo === ""
          ? "50000"
          : multiFamilyTo,
    });
  };

  const handleMarkersDraw = useCallback((event) => {
    if (event.latLngs && event.latLngs.length > 0) {
      let latLngItem = [];
      for (let item of event.latLngs[0]) {
        let cordinates = item.lat + ", " + item.lng;
        latLngItem.push({
          coordinates: cordinates.toString(),
        });
      }
      geoSubmit(latLngItem);
    } else {
      refresh();
    }
  }, []);

  const handleModeChange = useCallback(
    (event) => console.log("mode changed", event),
    []
  );

  const handlers = useMemo(
    () => ({
      markers: handleMarkersDraw,
      mode: handleModeChange,
    }),
    [handleMarkersDraw, handleModeChange]
  );

  const geoSubmit = (latLngItem) => {
    if(!isMobile){
      props.geoProperty(latLngItem);
    } else {
      let data = [];
      setPropertyData([]);
      setSelectedPlace({});
      setFromDate(null);
      setToDate(null);
      setPropertyData([]);
      setArchitect("");
      setDeveloper("");
      setMultiFamilyFrom("");
      setMultiFamilyTo("");
      for (let item of coords) {
        data.push({ coordinates: `${item[0]},${item[1]}` });
      }
      props.geoProperty(data);
    }
  };

  const handlePolygon = () => {
    if (freedrawVisible === NONE) {
      setFreedrawVisible(ALL);
    } else {
      setFreedrawVisible(NONE);
    }
  };
  const refresh = () => {
    props.setInitialState();
    setPropertyData([]);
    setSelectedPlace({});
    props.listProperty({
      from_date: "",
      to_date: "",
      architect: "",
      developer: "",
      multi_1: "",
      multi_2: "",
    });
  };

  const reset = () => {
    window.location.reload();
  };
  // const reset = () => {
  //CODE HERE ONLY
  // freeDraw.current.cancel();

  // freeDraw.mode(DELETE);
  // window.location.reload();
  // freedrawRef.current.remove(polygon);
  // setFreedrawVisible(DELETE);
  // freedrawRef.current.reset
  // props.setInitialState();
  // setFreedrawVisible(NONE);
  // setFromDate(moment("01/01/2019"));
  // setToDate(moment());
  // setPropertyData([]);
  // setArchitect("");
  // setDeveloper("");
  // setMultiFamilyFrom("");
  // setMultiFamilyTo("");
  // setSelectedPlace({});
  // props.listProperty({
  //   from_date: "",
  //   to_date: "",
  //   architect: "",
  //   developer: "",
  //   multi_1: "",
  //   multi_2: "",
  // });
  // };
  const icon = new L.Icon({
    iconUrl: require("../../../assets/img/pin.svg"),
    iconRetinaUrl: require("../../../assets/img/pin.svg"),
    iconAnchor: null,
    popupAnchor: [-3, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 37),
    className: "",
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const logout = () => {
    handle_Close();
    localStorage.removeItem("userData");
    props.history.push("/");
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = propertyData.filter((user) => {
        return (
          user.address.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user.architect.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user.developer.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setFilterData(results);
    } else {
      setFilterData(propertyData);
    }

    setSearchValue(keyword);
  };

  const cancelSearch = () => {
    setSearchValue("");
    setFilterData(propertyData);
  };
  
  
  const ClickComponent = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setCoords([...coords, [lat, lng]]);
      }
    });
    return null;
  }

  const handleEscapeKey = useCallback((event) => {
    if (event.key === "Escape") {
      freedrawRef.current.cancel();
    }
  }, []);
  useEffect(() => {
    window.addEventListener("keydown", handleEscapeKey);
    return () => window.removeEventListener("keydown", handleEscapeKey);
  }, [handleEscapeKey]);

  const [hide, setHide] = useState(false);
  return (
    <div className="container-fluid home-page homeone">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snackClose}
        message={message}
      />

      {props.pending && (
        <div className="loading">
          <CircularProgress />
          <span>Loading...</span>
        </div>
      )}
      <header className="container">
        <nav className="navbar fixed-top navbar-light bg-light extranav">
          <div className="left-nav">
            <div className="navbar-brand">
              <img src={Logo} width="30" height="30" alt="" />
            </div>
            <div className="top-bar mt-2 unit_tab">
              No. of units : <strong>{noOfUnits}</strong>
            </div>

            <div className="top-bar mt-2 project_tab">
              No. of Projects : <strong>{propertyData.length}</strong>
            </div>
            <div className="search_nav_bar">
              <Box
                component="form"
                justify="flex-end"
                sx={{
                  "& .MuiTextField-root": { m: "1", width: "13.6rem" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    className="search_nav_barinput"
                    placeholder="Search..."
                    type="text"
                    id="outlined-search"
                    size="small"
                    value={searchValue}
                    onChange={(e) => handleSearch(e)}
                    InputProps={{
                      endAdornment: searchValue && (
                        <IconButton
                          className="pd-5"
                          aria-label="toggle password visibility"
                          onClick={() => cancelSearch()}
                        >
                          <CancelRoundedIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </Box>
            </div>
            <div className="conditional_icon">
              <ExpandMoreIcon onClick={handleClick} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handle_Close}
              >
                <MenuItem onClick={changePassowrdLink}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>

          {/* logout */}
          <div className="navbar-user-info  right-nav">
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <img src={userIcon} alt="userIcon" />
                <span className="useremail">
                  {" "}
                  {userData1 && userData1.email}
                </span>
                <ExpandMoreIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handle_Close}
              >
                <MenuItem onClick={changePassowrdLink}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </nav>
        <div className="mob_dis">
          {/* Mobile view start */}
          <div className="d-flex justify-content-between">
            <div className="navbar-brand ml-2 mt-2">
              <img src={Logo} width="30" height="30" alt="" />
            </div>
            <div className="search_nav_bar unit_tab1 ml-2">
              <Box
                component="form"
                justify="flex-end"
                sx={{
                  "& .MuiTextField-root": { m: "1", width: "13rem" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    placeholder="Search"
                    type="text"
                    id="outlined-search"
                    size="small"
                    value={searchValue}
                    onChange={(e) => handleSearch(e)}
                    InputProps={{
                      endAdornment: searchValue && (
                        <IconButton
                          className="pd-5"
                          aria-label="toggle password visibility"
                          onClick={() => cancelSearch()}
                        >
                          <CancelRoundedIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </Box>
            </div>
            <div className="mob_dis">
              <ExpandMoreIcon
                className="mob_dis_icon"
                onClick={() => setShow(!show)}
              />
            </div>
          </div>
          {show ? (
            <div className="d-flex flex-column justify-content-center conditional_nav">
              <div className="top-bar unit_tab1">
                No. of units : <strong>{noOfUnits}</strong>
              </div>
              <div className="top-bar unit_tab1">
                No. of Projects : <strong>{propertyData.length}</strong>
              </div>
              <div className="unit_tab1">
                <ExpandMoreIcon onClick={handleClick} />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handle_Close}
                >
                  <MenuItem onClick={changePassowrdLink}>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          ) : null}
          {/* Mobile data end */}
        </div>
      </header>
      <div className="main-content">
        <div className="main-content-div">
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    className="main-content-item"
                  >
                    <DatePicker
                      placeholder="MM/YYYY"
                      className="date_picker_res"
                      label="CDR From Date"
                      format={"MM/yyyy"}
                      // maxDate={"12/1999"}
                      // handle clearing outside => pass plain array if you are not controlling value outside

                      value={fromDate}
                      openTo="month"
                      views={["year", "month"]}
                      onChange={(ev) => {
                        setFromDate(ev);
                      }}
                      animateYearScrolling={false}
                      autoOk={true}
                      clearable
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    className="width: 100%;"
                  >
                    <DatePicker
                      placeholder="MM/YYYY"
                      className="date_picker_res"
                      label="CDR To Date"
                      format={"MM/YYYY"}
                      //maxDate={"12/1999"}
                      openTo="month"
                      minDate={fromDate ? fromDate : moment().add(-29, "days")}
                      // handle clearing outside => pass plain array if you are not controlling value outside
                      views={["year", "month"]}
                      value={toDate}
                      onChange={(e) => {
                        setToDate(e);
                      }}
                      animateYearScrolling={false}
                      autoOk={true}
                      clearable
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="thirdcol_second mobile-view"
                    >
                      Multifamily Units from
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={multiFamilyFrom}
                      label="Multifamily units"
                      onChange={(e) => {
                        setMultiFamilyFrom(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value="0">0</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="250">250</option>
                      <option value="400">400</option>
                      <option value="1000">1000+</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="thirdcol_second"
                    >
                      Multifamily Units To
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={multiFamilyTo}
                      disabled={multiFamilyFrom === "1000" ? true : false}
                      label="Multifamily units"
                      onChange={(e) => {
                        setMultiFamilyTo(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value="0">0</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="250">250</option>
                      <option value="400">400</option>
                      <option value="1000">1000+</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="thirdcol_second"
                    >
                      Architect
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={architect}
                      label="Architect"
                      onChange={(e) => {
                        setArchitect(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {arcData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className="thirdcol_second"
                    >
                      Developer
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="thirdcol_second"
                      value={developer}
                      label="Developer"
                      onChange={(e) => {
                        setDeveloper(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {devData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={3} className="mt-3">
              <Grid container>
                <Button
                  aria-label="refresh"
                  variant="contained"
                  color="primary"
                  className="searchButton"
                  onClick={(e) => onSearch()}
                >
                  Search
                </Button>
                <Button
                  aria-label="refresh"
                  variant="contained"
                  className="searchButton reset"
                  onClick={() => reset()}
                >
                  Reset
                </Button>
                {!hide ? (
                  <Button
                    aria-label="refresh"
                    variant="contained"
                    className="hide-btn searchButton ml-3"
                    onClick={() => {
                      setHide(true);
                    }}
                  >
                    Map hide
                  </Button>
                ) : (
                  <Button
                    aria-label="refresh"
                    variant="contained"
                    className="hide-btn searchButton ml-3"
                    onClick={() => {
                      setHide(false);
                    }}
                  >
                    Map Show
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <br />
        <Grid container spacing={2} className="main-content-right-box1">
          <Grid item xs={12} sm={6} className="main-content-right1">
            <div className="map_component">
              {!hide && points.length > 0 && (
                <MapContainer
                  bounds={points}
                  center={center}
                  scrollWheelZoom={true}
                  className="mapview"
                  ref={mapRef}
                >
                  <Button
                    aria-label="refresh"
                    variant="contained"
                    className="refreshMap"
                    onClick={() => refresh()}
                  >
                    <RefreshIcon />
                  </Button>
                  {coords.length > 2 && (
                    <Button
                      aria-label="refresh"
                      style={{ left: "140px" }}
                      variant="contained"
                      color="primary"
                      className="refreshMap"
                      onClick={() => geoSubmit()}
                    >
                      Submit
                    </Button>
                  )}
                  {(!isMobile) &&
                  <Button
                    aria-label="refresh"
                    variant="contained"
                    color="primary"
                    className="refreshMap"
                    onClick={() => handlePolygon()}
                  >
                    {freedrawVisible === NONE ? "Enable Draw" : "Disable Draw"}
                  </Button>
                  }
                  <TileLayer
                    //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {(!isMobile) ?
                    <Freedraw
                      mode={freedrawVisible}
                      className={classes.mappointer}
                      maximumPolygons={1}
                      ref={freedrawRef}
                      eventHandlers={handlers}
                    /> : 
                      <>
                    <Polygon
                      positions={coords}
                      pathOptions={strockOptions}
                    />
                    <ClickComponent />
                    </>
                  }
                  {filterData.map(
                    (item, index) =>
                      item.latitude !== "" &&
                      !Number.isNaN(item.latitude) && (
                        <PointMarker
                          index={index}
                          center={[
                            parseFloat(item.latitude),
                            parseFloat(item.longitude),
                          ]}
                          item={item}
                          openPopup={selectedIndex === index}
                          onMarkerClick={onMarkerClick}
                          icon={icon}
                          key={index}
                        />
                      )
                  )}
                </MapContainer>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="main-content-right-box">
            <Grid
              container
              justifyContent="space-around"
              className="main-content-right"
              spacing={1}
            >
              {filterData.length > 0 ? (
                filterData.map((item, index) => (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={6}
                    key={index}
                    className="card_render_data"
                  >
                    <div className="card">
                      <div>
                        {index <= 8 ? (
                          <div className="numbers">
                            <p>{index + 1}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        id={item.id}
                        key={item.id}
                        onClick={() => {
                          onMarkerClick(item, index);
                        }}
                        className={
                          selectedPlace.id === item.id
                            ? "active pb-10"
                            : "inactive pb-10"
                        }
                      >
                        <div>
                          <LazyImageWithBox
                            className="card-img-top img-fluid card-image-res"
                            src={[item.picture_1]}
                           
                          />
                         
                        </div>
                        <div className="d-flex mx-2 mt-2">
                          <p className="card-title mr-2">
                            <b>Address:</b>
                          </p>
                          <p className="card-title address">{item.address}</p>
                        </div>
                        {item.architect && item.architect.trim() !== "" && (
                          <div className="d-flex mx-2">
                            <p className="card-title mr-1">-</p>
                            <p className="card-title address">
                              {item.architect}
                            </p>
                          </div>
                        )}
                        {item.developer && item.developer.trim() !== "" && (
                          <div className="d-flex mx-2">
                            <p className="card-title mr-1">-</p>
                            <p className="card-title address">
                              {item.developer}
                            </p>
                          </div>
                        )}
                        {item.multi_family !== 0 && (
                          <div className="d-flex mx-2">
                            <p className="card-title mr-1">-</p>
                            <p className="card-title address">
                              {item.multi_family} Units //{" "}
                              {item.building_grossfloor_area} GSF{" "}
                            </p>
                          </div>
                        )}
                        <div className="d-flex mx-2">
                          {item.cdr_file && (
                            <a
                              href={item.cdr_file}
                              className="file-preview"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              CDR File
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </Grid>
                ))
              ) : (
                <h6 className="no-data">No Data Found</h6>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: homePropertyError(state),
  listPropertyResponse: homePropertyResponse(state),
  pending: homePropertyPending(state),
  geoError: geoPropertyError(state),
  geoPropertyResponse: geoPropertyResponse(state),
  geoPending: geoPropertyPending(state),
  listDevError: listDevError(state),
  listDevResponse: listDevResponse(state),
  listDevPending: listDevPending(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listProperty: listHomeProperty,
      geoProperty: geoHomeProperty,
      setInitialState: setInitalState,
      listDevProperty: listDevProperty,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeOnePage);
