import {loginPending, loginSuccess, loginError,UserloginPending,UserloginSuccess,UserloginError,
    forgotPasswordPending, forgotPasswordSuccess, forgotPasswordError,userforgotPasswordPending,
    userforgotPasswordSuccess,userforgotPasswordError,
    resetPasswordPending, resetPasswordSuccess, resetPasswordError,userResetPasswordPending,
    userResetPasswordSuccess,userResetPasswordError,UsersignupPending,UsersignupSuccess,UsersignupError
} from './index';
import { base_url, base_version,} from './../config';
import { sha3_512 } from 'js-sha3';


export function userSignup(data) {
    data.password = sha3_512(data.password)
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(UsersignupPending());
        fetch(`${base_url}${base_version}user/signup`,requestOptions)
        .then(res => (res.json()))
        .then(res => {
            if(res.success === false) {
                let error = {error:res.message}
                throw(error);
            }
            dispatch(UsersignupSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(UsersignupError(error));
        })
    }
}

export function login(data) {
    data.password = sha3_512(data.password)
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json' },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(loginPending());
        fetch(`${base_url}${base_version}admin/login`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Email or password is not correct'}
                throw(error);
            }
            dispatch(loginSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(loginError(error));
        })
    }
}



export function Userlogin(data) {
    data.password = sha3_512(data.password)
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json', },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(UserloginPending());
        fetch(`${base_url}${base_version}user/login`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Email or password is not correct'}
                throw(error);
            }
            dispatch(UserloginSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(UserloginError(error));
        })
    }
}


export function forgotPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json', },
        body: JSON.stringify(data)
    };
    return dispatch => {
        dispatch(forgotPasswordPending());
        fetch(`${base_url}${base_version}admin/forgot_password`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(forgotPasswordSuccess(res));
            return res.data;
        })
        .catch(error => {
            dispatch(forgotPasswordError(error));
        })
    }
}


export function userforgotPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json',
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
        "Access-Control-Max-Age": "36000",
        "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(data)
    };
    // /user/forgot_password
    return dispatch => {
        dispatch(userforgotPasswordPending());
        fetch(`${base_url}${base_version}user/forgot_password`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(userforgotPasswordSuccess(res));
            return res.data;
        })
        .catch(error => {
            dispatch(userforgotPasswordError(error));
        })
    }
}


export function resetPassword(data) {
    let formData = {
        token:data.token,
        password:sha3_512(data.password)
    }
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json',
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
        "Access-Control-Max-Age": "36000",
        "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(formData)
    };
    return dispatch => {
        dispatch(resetPasswordPending());
        fetch(`${base_url}${base_version}admin/resetpassword`,requestOptions)
        .then(res => res.json())
        .then(res => {
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(resetPasswordSuccess(res));
            return res.data;
        })
        .catch(error => {
            dispatch(resetPasswordError(error));
        })
    }
}


export function userResetPassword(data) {
    
    let formData = {
        token:data.token,
        password:sha3_512(data.password)
    }
    
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": 'application/json',
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
        "Access-Control-Max-Age": "36000",
        "Access-Control-Allow-Headers": "x-requested-with, content-type" },
        body: JSON.stringify(formData)
    };
    return dispatch => {
        dispatch(userResetPasswordPending());
        fetch(`${base_url}${base_version}user/resetpassword`,requestOptions)
        .then(res => res.json())
        .then(res => {
            console.log(res, "responce");
            if(res.success === false) {
                let error = {error:'Something went wrong...!'}
                throw(error);
            }
            dispatch(userResetPasswordSuccess(res));
            return res.data;
        })
        .catch(error => {
            dispatch(userResetPasswordError(error));
        })
    }
}
