import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import {resetPassword} from '../../../actions/login';
import {getResetPasswordError, getResetPasswordResponse, getResetPasswordPending} from '../../../reducers/index';


import "./ResetPassword.css";
import { setInitalState } from '../../../actions';
function ResetPasswordPage(props) {
    const { register, handleSubmit, errors  } = useForm();
    const [open, setOpen] = React.useState(false);
    const [message,setMessage] = React.useState('');   

    useEffect(() => {
        
        if(localStorage.getItem('userData')){
            props.history.push('/admin/dashboard')
        }
        if(props.resetPasswordResponse)
        {    
            snackClick(props.resetPasswordResponse.message)         
            setTimeout(() => {         
                props.setInitialState() 
                props.history.push('/admin/login')
            }, 1000);
        }else if(props.error){
            snackClick(props.error.error)            
            props.setInitialState()
        }
    }, [props])

    const snackClick = (message) => {
        setMessage(message)
        setOpen(true);
      };
    
    const snackClose = (event, reason) => {
        setOpen(false);
    };

    // const goToHome = () => props.history.push('/dashboard');
    const onSubmit = (data) => {
        if(data.password !== data.confirm_password){
            snackClick('New password & Confirm password not matched') 
        }else{
            data.token = (window.location.href).split('/').pop()
            props.resetPassword(data);
        }
    } 
    return (
        
            <div id="login">
                {
                props.pending &&
                    <div className="loading">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={snackClose}
                    message={message}
                />
                <div className="container">
                    <div id="login-row" className="row justify-content-center align-items-center">
                        <div id="login-column" className="col-md-6">
                            <div id="login-box" className="col-md-12">
                                <h3 className="text-center text-info mt-3">Reset Password</h3>                                
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label for="password" className="text-dark">New Password:</label><br/>
                                        <input type="password" autoComplete="off" ref={register({ required: true})}  name="password" id="password" className="form-control" />
                                        {errors.password && <span className="error">New password is required</span>}
                                    </div>
                                    <div className="form-group">
                                        <label for="confirm_password" className="text-dark">Confirm Password:</label><br/>
                                        <input type="password" autoComplete="off" ref={register({ required: true})}  name="confirm_password" id="confirm_password" className="form-control" />
                                        {errors.confirm_password && <span className="error">Confirm password required</span>}
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-info btn-md" >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}


const mapStateToProps = state => ({
    error: getResetPasswordError(state),
    resetPasswordResponse: getResetPasswordResponse(state),
    pending: getResetPasswordPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    resetPassword: resetPassword,
    setInitialState:setInitalState
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordPage );
