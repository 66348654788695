import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Snackbar from '@material-ui/core/Snackbar';
import {userforgotPassword} from '../../../actions/login';
import {usergetForgotPasswordError, usergetForgotPasswordResponse, usergetForgotPasswordPending} from '../../../reducers/index';
import { setInitalState } from '../../../actions';
import Snackbar from '@material-ui/core/Snackbar';
import "./ForgotPasswordHome.css";
function ForgotPasswordHome(props) {
    const { register, handleSubmit, reset, errors  } = useForm();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');   

    useEffect(() => {
        if(localStorage.getItem('userData')){
            props.history.push('/home')
        }
        if(props.ForgotPasswordResponse)
        {    
            reset()
            snackClick(props.ForgotPasswordResponse.message)         
            props.setInitialState()
        }else if(props.error){
            snackClick(props.error.error)            
            props.setInitialState()
        }
    }, [props])

    const snackClick = (message) => {
        setMessage(message)
        setOpen(true);
      };
    
    const snackClose = (event, reason) => {
        setOpen(false);
    };

    // const goToHome = () => props.history.push('/dashboard');
    const onSubmit = (data) => {
        props.userforgotPassword(data);
    } 

    const loginLink = () => {
        props.history.push('/')
    }
    return (
        
            <div id="login">
                 <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={snackClose}
                    message={message}
                />
                {props.pending && (
        <div className="loading">
          <CircularProgress />
          <span>Loading...</span>
        </div>
      )}
                <div className="container">
                    <div id="login-row" className="row justify-content-center align-items-center">
                        <div id="login-column" className="col-md-6">
                            <div id="login-box" className="col-md-12">
                                <h3 className="text-center text-info mt-3">Forgot Password</h3>                                
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label for="email" className="text-info">Email:</label><br/>
                                        <input type="email"  name="email" id="email"  ref={register({ required: true})} className="form-control" />
                                        {errors.email && <span className="error">Email is required</span>} 
                                   
                                    </div>
                                    <div className="form-group">
                                        <label className="float-right" onClick={() => loginLink()}>Back to Login</label><br/>
                                        <button type="submit" className="btn btn-info btn-md" >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = state => ({
    error: usergetForgotPasswordError(state),
    ForgotPasswordResponse: usergetForgotPasswordResponse(state),
    pending: usergetForgotPasswordPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    userforgotPassword: userforgotPassword,
    setInitialState:setInitalState
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordHome );

